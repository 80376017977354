import React from "react";
import { formatNumberWithcommas } from "../../helpers";
import CommonCard from "../CommonCard";
import PercentageStatDisplay from "../PercentageStatDisplay";
import classes from "./StatisticsCard.module.css";
import NoRecordFound from "../NoRecordFound";
import { numberFormatter } from "../../helpers";

const StatisticsCard = ({ className, style, data }) => {
  return (
    <CommonCard
      className={[classes.wrapper, className].join(" ")}
      style={style}
    >
      <ul className={[classes.topList].join(" ")}>
        <li>
          <h2 className={[classes.mainHeading, "mb-1"].join(" ")}>
            {data?.title ? data?.title : "Total Doctors"}
          </h2>
          <div className={classes.content}>
            <h3 className={classes.value}>{numberFormatter(data?.number)}</h3>

            <img src={data?.img} alt="man" className={classes.img} />
          </div>
        </li>
      </ul>
      <div className="mt-auto">
        <PercentageStatDisplay
          text={data?.percent}
          background={"var(--honey-dew)"}
        />
      </div>
      {/* <NoRecordFound /> */}
    </CommonCard>
  );
};

export default StatisticsCard;
