import {
  fetchAllUserListingApi,
  addUserInToAPI,
  updateUserAPI,
  getUserByIdApi,
  getAllActiveUsersApi,
} from "./userApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchAllUserListing = createAsyncThunk(
  "user/get-user-list",
  fetchAllUserListingApi
);

export const addUser = createAsyncThunk("user/add-user", addUserInToAPI);
export const updateUser = createAsyncThunk("user/update-user", updateUserAPI);
export const getUserById = createAsyncThunk(
  "user/get-user-ById",
  getUserByIdApi
);
export const getAllActiveUsers = createAsyncThunk(
  "user/get-all-users",
  getAllActiveUsersApi
);
