import React from "react";
import classes from "./CollapsableSearch.module.css";
import { Input } from "../Input";
import { BsSearch } from "react-icons/bs";
import { useState } from "react";
import Button from "../Button";
import FilterActionButton from "../FilterActionButton";
const CollapsableSearch = ({
  search,
  setSearch = () => {},
  placeholder,
  icon,
  leftIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex_1rem_gap g-0 h-100">
      <div
        className={[
          classes.inputElementWrapper,

          !isOpen && classes.collapse,
        ].join(" ")}
      >
        <Input
          parentCustomStyle={{
            cursor: "pointer",
            flex: 1,
            borderRadius: "6px",
          }}
          customStyle={{
            gap: "10px",
            paddingLeft: "10px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
          inputStyle={{ padding: "8px", paddingLeft: "0px" }}
          setter={setSearch}
          value={search}
          placeholder={placeholder || "Search Here ...."}
          leftIcon={
            <Button
              style={{ marginLeft: "-10px", cursor: "pointer" }}
              className="button_transparent p-0"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {leftIcon ? leftIcon : <BsSearch color="var(--medium-gray)" />}
            </Button>
          }
        />
      </div>
      {!isOpen && (
        <FilterActionButton
          className={classes.iconHolder}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {icon}
        </FilterActionButton>
      )}
    </div>
  );
};

export default CollapsableSearch;
