import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchLocationTypeListApi,
  getAllActiveLocationTypeApi,
  addLocationTypeIntoApi,
  updateLocationTypeApi,
} from "./locationTypeApi";

//fetch all active or inactive
export const fetchLocationTypes = createAsyncThunk(
  "locationtype/get-all-location-types",
  fetchLocationTypeListApi
);

//get all active only
export const getAllActiveLocationTypes = createAsyncThunk(
  "locationtype/get-location-types",
  getAllActiveLocationTypeApi
);

export const addLocationType = createAsyncThunk(
  "location/add-location-type",
  addLocationTypeIntoApi
);

export const updateLocationType = createAsyncThunk(
  "location/update-location-type",
  updateLocationTypeApi
);
