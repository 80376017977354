import { toast } from "react-toastify";
import { Post, Get } from "../../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../../config";
import { getToken } from "../../helpers/tokenStorage";
// src/api/medicineAPI.js
//get all medicine handler
export const fetchMedicineList = async (
  pageNo = 1,
  limit = 10,
  search = "",
  sortField = "",
  sortDirection = "desc"
) => {
  const response = await Post(
    BaseURL("medicine/get-medicine-list"),
    {
      pageNumber: pageNo,
      pageSize: 10,
      totalRowsCount: 0,
      sortField,
      sortDirection,
      search,
      filter: {
        locationId: 0,
        medicineId: 0,
        doctorId: 0,
        fromDate: "",
        toDate: "",
        targetStatus: "",
        targetFilter: {
          minValue: 0,
          maxValue: 0,
        },
      },
    },
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.error);
    return [];
  }
};
//add medicine handler
export const addMedicineToAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/add-medicine"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Medicine Created Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//update medicine
export const updateMedicineInAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/update-medicine"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Medicine Updated Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
  // return response.data;
};
//delete medicine
export const deleteMedicineFromAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/delete-medicine"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Medicine Deleted Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//get Medicine By Id
export const getMedicineByIdFromAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/get-medicine-byId"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) return response?.data?.body;
  toast.error(response?.data?.headers?.message);
  return null;
};
//link doctor
export const linkDoctorTargetFromAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/link-doctor-target"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    toast.success("Doctor Link Successfully");
    return true;
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//modify target
export const modifyTargetAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/update-medicine-target"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    toast.success("Medicine Prescription Target Update  Successfully");
    //handle get all medicines
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//close target
export const closeTargetAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/close-target"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    toast.success(`${params?.medicineName} target close successfully`);
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//close target
export const closeCreateMedicineTargetAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/close-create-medicine-target"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    toast.success(`${params?.medicineName} target close successfully`);
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
export const getAllMedicineUnitsAndTypeApi = async () => {
  const response = await Promise.allSettled([
    Get(BaseURL("Medicine/get-medicine-unit"), getToken()),
    Get(BaseURL("Medicine/get-medicine-type"), getToken()),
  ]);
  const result = response?.map((api) => api?.value?.data?.body);
  return result;
};
