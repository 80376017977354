// src/features/medicine/medicineSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchRoles } from "../../../api/role/roleThunk";
import { createAsyncCases } from "../../../helpers/reduxUtils";
const initialState = {
  items: [],
  status: "idle",
  loading: null,
  error: null,
  totalRowsCount: 0,
  selectedItem: null,
};
const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    handleSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get All Medicine
    createAsyncCases(builder, fetchRoles, {
      fulfilled: (state, action) => {
        state.items = action?.payload?.data;
        state.totalRowsCount = action?.payload?.pagination?.totalRowsCount;
      },
    });
  },
});
export const { handleSelectedItem } = roleSlice.actions;
export default roleSlice.reducer;
