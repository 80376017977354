import React, { useState, Suspense, useEffect } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
import Router from "./routes";
import TableData from "./components/Table";
import SidebarSkeleton from "./layouts/user/SidebarSkeleton";
import MultipleFileUpload from "./components/FileUploader";
import { Dropdown } from "./components/Dropdown";
import AreYouSureModal from "./modals/AreYouSureModal";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./styles/tabs.css";
import "../src/styles/table.css";
const App = () => {
  const [check, setcheck] = useState(null);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    document.querySelectorAll("input").forEach((input) => {
      input.setAttribute("autocomplete", "off");
      input.setAttribute(
        "name",
        "dummy-" + Math.random().toString(36).substring(7)
      );
    });
  }, []);
  return (
    <div>
      <ToastContainer />

      <HashRouter>
        <Suspense fallback={<Loader />}>
          {/* <Dropdown
            options={[
              { label: "ci", value: "cd" },
              { label: "ci", value: "cd" },
              { label: "ci", value: "cd" },
              { label: "ci", value: "cd" },
            ]}
            setter={setcheck}
            value={check}
          />
          <MultipleFileUpload />
          <TableData /> */}
          <Router />
        </Suspense>
      </HashRouter>
    </div>
  );
};

export default App;
