import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./combineAllReducers";
import { thunk } from "redux-thunk";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["authReducer"],
  blacklist: ["commonReducer"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export const persistor = persistStore(store);
export default store;
