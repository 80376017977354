import React, { useState, useEffect } from "react";
import { BsCalendar4Event, BsGraphUpArrow } from "react-icons/bs";
import { CiPause1 } from "react-icons/ci";
import { SlLocationPin } from "react-icons/sl";
import { militaryLogo } from "../../assets/constant";
import useClickOutside from "../../customHooks/useClickOutside";
import PopperMedicineTarget from "../AllPoppers/Medicine/PopperMedicineTarget";
import PopperMedicineEstimateTarget from "../AllPoppers/MedicineEstimatedValue";
import CollapsableSearch from "../CollapsableSearch";
import CommonCard from "../CommonCard";
import DateRangeCalendar from "../DateRangeSelector";
import { Dropdown } from "../Dropdown";
import FilterActionButton from "../FilterActionButton";
import PopperMenu from "../PopperMenu";
// import PopupContainer from "../PopupContainer";
import classes from "./TableHeaderFilter.module.css";
import PopoverTrigger from "../PopoverTrigger";
import { BsSearch } from "react-icons/bs";
import { Input } from "../Input";
import Button from "../Button";
import { MilitaryIcon } from "../../assets/Icon";
const lists = ["All", "Acheived", "Not Acheived"];
const allLimits = [10, 20, 30, 50];
const TableHeaderFilter = ({
  label,
  tableFields,
  filterActions,
  decideFilterActions = {
    calender: true,
    mainSearch: true,
    achieved: true,
    target: true,
    locationSearch: true,
    customTarget: true,
  },
}) => {
  const {
    search,
    setSearch,
    limit,
    setLimit,
    showCalender,
    setShowCalender,
    maxTarget,
    minTarget,
    setMaxTarget,
    setMinTarget,
    filterBystatus,
    setFilterBystatus = () => {},
  } = filterActions;

  const {
    calender,
    mainSearch,
    achieved,
    target,
    locationSearch,
    customTarget,
  } = decideFilterActions;
  const removeCalendar = () => {
    setShowCalender && setShowCalender(false);
  };
  const [calenderRef] = useClickOutside(removeCalendar);
  const [limitVal, setLimitVal] = useState(0);
  const [pointedEle, setPointedEle] = useState(null);
  const [] = useClickOutside();

  const openLimitHandler = (event) => {
    setPointedEle(event?.target?.value);
  };
  return (
    <div className={classes["table__header"]}>
      <h2 className={classes["table__title"]}>{label}</h2>
      <ul className={[classes["filter__actionWrapper"]].join(" ")}>
        {mainSearch && (
          <li>
            <Input
              customStyle={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
              }}
              inputStyle={{
                paddingLeft: "0px",
                paddingBlock: "6px",
                maxWidth: "178px",
              }}
              parentCustomStyle={{ flex: 1, borderRadius: "6px" }}
              setter={setSearch}
              value={search}
              placeholder={"Search Here ...."}
              leftIcon={
                <Button
                  style={{ marginLeft: "-10px", cursor: "pointer" }}
                  className="button_transparent p-0"
                  onClick={() => {}}
                >
                  <BsSearch color="var(--medium-gray)" />
                </Button>
              }
            />
          </li>
        )}
        {calender && (
          <li style={{ position: "relative" }}>
            <PopperMenu
              component={<DateRangeCalendar />}
              listStyle={{ right: "50%", top: "50px" }}
            
              setter={() => {}}
            >
              <FilterActionButton
                className={classes.iconHolder}
                label={<BsCalendar4Event />}
              />
            </PopperMenu>
            {/* <FilterActionButton
              className={classes.iconHolder}
              ref={calenderRef}
              onClick={() => {
                setShowCalender && setShowCalender(true);
              }}
            >
            
              <BsCalendar4Event />
            </FilterActionButton>
            <PopupContainer show={showCalender} setShow={setShowCalender}>
              <DateRangeCalendar />
            </PopupContainer> */}
          </li>
        )}
        {/* <li>
          <CollapsableSearch
            search={search}
            setSearch={setSearch}
            icon={<BsCalendar4Event />}
          />
        </li> */}
        {locationSearch && (
          <li>
            <CollapsableSearch
              search={search}
              setSearch={setSearch}
              icon={<SlLocationPin />}
            />
          </li>
        )}
        {target && (
          <li style={{ position: "relative" }}>
            <PopperMedicineTarget setter={setMaxTarget} value={maxTarget} />
          </li>
        )}
        {customTarget && (
          <li style={{ position: "relative" }}>
            <PopperMedicineEstimateTarget
              setter={setMaxTarget}
              value={maxTarget}
            />
          </li>
        )}
        {achieved && (
          <li>
            <PopperMenu lists={lists} setter={setFilterBystatus}>
              <FilterActionButton className={classes.iconHolder}>
                <MilitaryIcon />
                {/* <img
                  src={militaryLogo}
                  className={classes.militaryImg}
                  alt="military logo"
                /> */}
              </FilterActionButton>
            </PopperMenu>
          </li>
        )}
      </ul>

      <div className={classes["show-pages-and-colummns"]}>
        <p className={classes.pageIndicator}>Results per page</p>
        {/* <CommonCard className={classes.pageValue__wrapper}>
          <Dropdown
            style={{ height: "34px", width: "34px" }}
            setter={setLimit}
            value={limit}
            optionsWidth="fit-content"
            dropdownIndicator={false}
            placeholder={""}
            options={[
              { label: 10, value: "10" },
              { label: 30, value: "30" },
              { label: 60, value: "60" },
            ]}
            customStyle={{ padding: "0px", width: "32px" }}
          />
        </CommonCard> */}
        <div
          aria-describedby={"doctor-limit"}
          variant="contained"
          className={classes.limitInput}
          onClick={(event) => {
            setPointedEle(event.currentTarget);
          }}
        >
          {limitVal}
        </div>
        <PopoverTrigger
          isIcon={false}
          lists={allLimits}
          setter={setLimitVal}
          onClick={() => {}}
          anchorEle={pointedEle}
          setAnchorEle={setPointedEle}
        />
        <PopperMenu
          listStyle={{ right: "50%", top: "50px" }}
          lists={tableFields}
          setter={() => {}}
        >
          <FilterActionButton
            className={classes.iconHolder}
            label={<CiPause1 size={20} color="var(--medium-gray)" />}
          />
        </PopperMenu>
      </div>
    </div>
  );
};

export default TableHeaderFilter;
