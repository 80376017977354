import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "../Button";
import { HiOutlineDotsVertical } from "react-icons/hi";
import classes from "./PopoverTrigger.module.css";
export default function PopoverTrigger({
  lists = [],
  setter=()=>{},
  children,
  listsClass = "",
  listStyle = null,
  onClick,
  isIcon = true,
  anchorEle,
  setAnchorEle,
}) {
  const handleClick = (event) => {
    onClick && onClick();
    setAnchorEle(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEle(null);
  };

  const open = Boolean(anchorEle);
  const id = open ? "simple-popover" : undefined;
  const [isItemselected, setIsItemselected] = React.useState(-1);

  return (
    <>
      <style>
        {`
  .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 8px !important;
  background-color: var(--white-color) !important;
}

    `}
      </style>

      <div>
        {/* {isIcon && (
          <span aria-describedby={id} variant="contained" onClick={handleClick}>
            <HiOutlineDotsVertical color="var(--slate-blue)" size={23} />
          </span>
        )} */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEle}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.mainPopper}
        >
          <ul className="list-style-none p-2 m-0">
            {lists?.map((list, index) => (
              <li className={classes.actionItem} key={index}>
                <span
                  className={[
                    classes["button-item"],
                    "button_transparent",
                    "d-block",
                    isItemselected === index && classes.selected,
                  ].join(" ")}
                  onClick={() => {
                    setter(list);
                    setIsItemselected(index);
                  }}
                >
                  {list}
                </span>
              </li>
            ))}
          </ul>
        </Popover>
      </div>
    </>
  );
}
