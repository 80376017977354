import React, { forwardRef, useRef } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa";
import classes from "./MenuItem.module.css";
const MenuItem = forwardRef(
  (
    {
      index,
      text,
      icon,
      path,
      listOfOptions = [],
      setIsSidebarClose,
      activeHandler,
      isActive,
      setIsSidebarOpen,
      isSidebarClose,
      isClickOnToggle,
      className,
      markAsActive,
      setMarkAsActive,
      leftSideLink,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const location = useLocation()?.pathname.split("/")[1];
    const menuItem = useRef(null);
    const itemRef = useRef(null);
    const handleActive = (id) => {
      if (window.innerWidth <= 992) {
        setIsSidebarClose(true);
      }

      if (listOfOptions?.length <= 0) {
        navigate(path.split("/")[1]);
      } else if (listOfOptions === null) {
        navigate(path.split("/")[1]);
      }
      listOfOptions?.length > 0 && activeHandler(id);
    };
    const handleMouseEnter = (e) => {
      //isClickOntoggle comes from sidebarSkeleton and it's define does user click on toggle
      if (!isClickOnToggle) {
        if (ref?.current?.offsetWidth == 58) {
          setIsSidebarClose(false);
        } else {
          setIsSidebarClose(true);
        }
      }
    };

    const leftSideHandler = () => {
      navigate(path.split("/")[1]);
    };

    return (
      <li
        ref={menuItem}
        onMouseEnter={handleMouseEnter}
        onClick={() => {
          // setIsSidebarClose(false);
          // markAsActiveHandler();
        }}
        className={[className].join(" ")}
      >
        <div
          ref={itemRef}
          className={[
            classes["menuItem"],
            markAsActive && classes["markAsActive"],
            classes["overflow-x-hidden"],
            isActive && classes.thisActive,
            // (text === "Dashboard" ? classes.activeMenu : location) &&
            //   (isActive || location === path) &&
            //   classes.activeMenu,
          ].join(" ")}
          onClick={() => {
            handleActive && handleActive();
            setMarkAsActive && setMarkAsActive();
          }}
        >
          <div
            className={[
              classes["menuContent"],
              classes["overflow-x-hidden"],
            ].join(" ")}
            onClick={leftSideLink && leftSideHandler}
          >
            {icon ? (
              <div className={classes["menuIcon-wrapper"]}>{icon}</div>
            ) : (
              <span className={classes["circle-indicator"]} />
            )}
            <p className={classes["menu-name"]}>{text}</p>
          </div>

          {listOfOptions?.length > 0 && (
            <span className={classes["menuIndicator"]}>
              <FaCaretRight
                className={`mr-2 ${isActive && classes["arrowDown"]}`}
              />
            </span>
          )}
        </div>

        <div
        // className={[
        //   classes["collapsible-menu"],
        //   isActive && classes["opened"],
        //   classes["parent-collapsible"]
        // ].join(" ")}
        // className={`${classes["collapsible-menu"]} ${
        //   isActive && classes["opened"]
        // }`}
        >
          <div
            className={`${classes["list-wrapper"]} ${
              isActive && listOfOptions?.length > 0 && classes["nested-option"]
            } `}
          >
            <ul className={classes["transition-effect"]}>
              {listOfOptions?.map((item, indx) => {
                return (
                  <MenuItem
                    key={indx}
                    index={indx}
                    path={item.router}
                    text={item.name}
                    listOfOptions={null}
                    setIsSidebarClose={setIsSidebarClose}
                    icon={item?.icon}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </li>
    );
  }
);
export default MenuItem;
