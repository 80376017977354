import { createSlice } from "@reduxjs/toolkit";
import { createAsyncCases } from "../../../helpers/reduxUtils";
import {
  fetchLocations,
  addLocation,
  updateLocation,
  getLocationById,
} from "../../../api/location/locationThunk";
const initialState = {
  items: [],

  status: "idle",
  loading: false,
  error: null,
  totalRowsCount: 0,
  selectedItem: null,
};
const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    handleSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers(builder) {
    createAsyncCases(builder, fetchLocations, {
      fulfilled(state, action) {
        state.items = action?.payload?.data;
        state.totalRowsCount = action?.payload?.pagination?.totalRowsCount;
      },
    });
    createAsyncCases(builder, addLocation, {
      fulfilled(state, action) {},
    });
    createAsyncCases(builder, updateLocation, {
      fulfilled(state, action) {},
    });
    createAsyncCases(builder, getLocationById, {
      fulfilled(state, action) {},
    });
  },
});

export default locationSlice.reducer;
export const { handleSelectedItem } = locationSlice.actions;
