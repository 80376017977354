import classes from "./DateRange.module.css";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "./DefaultRangeSelector";
import { format } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./DateRange.css";
import PropTypes from "prop-types";
const initialValue = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};
const DateRangeSelector = ({
  ranges,
  onSubmit,
  setter = () => {},
  value = { initialValue },
  ...rest
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState(
    value || initialValue
  );
  const [show, setShow] = useState(false);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [isCustom, setIsCustom] = useState(false);

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "MM/dd/yyyy");
  }

  const handleSelect = (ranges) => {
    const range = ranges.selection;
    setter(range);
    // setSelectedDateRange(range);
    // setIsCustom(range?.label === "Custom");
  };

  const onClickClear = () => {
    const resetRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    };
    setter(resetRange);
    setSelectedDateRange(resetRange);
    setShow(false);
  };

  const handleCustomRangeSubmit = (e) => {
    e.preventDefault();
    const startDate = new Date(customStartDate);
    const endDate = new Date(customEndDate);
    if (startDate && endDate) {
      const customRange = {
        startDate,
        endDate,
        key: "selection",
      };
      setter(customRange);
      setSelectedDateRange(customRange);
      setShow(true);
      setIsCustom(false); // Hide the custom date form after setting the custom range
    }
  };

  return (
    <React.Fragment>
      <div className="shadow d-inline-block">
        <DateRangePicker
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[selectedDateRange]}
          staticRanges={defaultStaticRanges}
          inputRanges={[]} // Disable input ranges
          direction="vertical"
        />
        <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
          <button
            className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
            onClick={() => setShow(true)}
          >
            Done
          </button>
          <button
            className="btn btn-transparent text-danger rounded-0 px-4"
            onClick={onClickClear}
          >
            Clear
          </button>
        </div>
        {isCustom && (
          <form onSubmit={handleCustomRangeSubmit}>
            <input
              type="date"
              value={customStartDate}
              onChange={(e) => setCustomStartDate(e.target.value)}
            />
            <input
              type="date"
              value={customEndDate}
              onChange={(e) => setCustomEndDate(e.target.value)}
            />
            <button type="submit">Set Custom Range</button>
          </form>
        )}
      </div>
    </React.Fragment>
  );
};

DateRangeSelector.defaultProps = {
  ranges: defaultStaticRanges,
};

DateRangeSelector.propTypes = {
  onSubmit: PropTypes.func,
  setter: PropTypes.func.isRequired,
  value: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
    key: PropTypes.string,
  }),
};

export default DateRangeSelector;
