import { toast } from "react-toastify";
import { Post } from "../../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../../config";
import { getToken } from "../../helpers/tokenStorage";
// src/api/medicineAPI.js
//get all medicine handler
export const fetchDoctorList = async (
  pageNo = 1,
  limit = 10,
  search = "",
  sortField = "",
  sortDirection = "desc"
) => {
  const response = await Post(
    BaseURL("doctor/get-doctor-list"),
    {
      pageNumber: pageNo,
      pageSize: 10,
      totalRowsCount: 0,
      sortField,
      sortDirection,
      search,
      filter: {
        locationId: 0,
        medicineId: 0,
        doctorId: 0,
        fromDate: "",
        toDate: "",
        targetStatus: "",
        targetFilter: {
          minValue: 0,
          maxValue: 0,
        },
      },
    },
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.error);
    return [];
  }
};
//add doctor handler
export const addDoctorToAPI = async (params) => {
  const response = await Post(
    BaseURL("doctor/add-doctor"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Doctor Created Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//update doctor
export const updateDoctorInAPI = async (params) => {
  const response = await Post(
    BaseURL("medicine/update-medicine"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Doctor Updated Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
  // return response.data;
};
//delete medicine
export const deleteDoctorFromAPI = async (params) => {
  const response = await Post(
    BaseURL("doctor/delete-doctor"),
    params,
    apiHeader(getToken())
  );

  if (response?.data?.headers?.error == 200) {
    toast.success("Doctor Deleted Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//get Doctor By Id
export const getDoctorByIdAPI = async (params) => {
  const response = await Post(
    BaseURL("doctor/get-doctor-ById"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) return response?.data?.body;
  toast.error(response?.data?.headers?.message);
  return null;
};

export const activeOrInactiveInAPI = async (params) => {
  const response = await Post(
    BaseURL("doctor/activeinactive-doctor"),
    params,
    apiHeader(getToken())
  );

  if (response?.data?.headers?.error == 200) {
    toast.success("Doctor Deleted Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//link doctor to medicine
export const linkDoctorToMedicineInAPI = async (params) => {
  const response = await Post(
    BaseURL("doctor/link-medicine-target"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    toast.success("Doctor Link to Medicine Link Successfully");
    return true;
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
