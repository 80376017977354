import React from "react";
import classes from "./PercentageStatDisplay.module.css";
const PercentageStatDisplay = ({
  color = "var(--medium-aquamarine)",
  background,
  text = null,
}) => {
  return (
    <div className="flex_1rem_gap">
      <div className={classes.wrapper} style={{ background }}>
        <p className={classes.value} style={{ color }}>
          13%
        </p>
      </div>
      <p className={classes.strip}>Since the last month</p>
    </div>
  );
};

export default PercentageStatDisplay;
