import React from "react";
import ReactSelect, { components } from "react-select";
import classes from "./Dropdown.module.css";
import PropTypes from "prop-types";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

export const Dropdown = ({
  isRequired = true,
  options,
  label,
  labelTwo,
  customStyle,
  disabled,
  value,
  setter = () => {},
  noBorder,
  placeholder,
  placeholderStyle,
  isMulti,
  style,
  showField,
  Components,
  labelClassName,
  indicatorColor = "var(--medium-gray)",
  optionLabel,
  optionValue,
  leftIconClass,
  selectRef,
  isSearchable = false,
  dropdownIndicator = true,
  dropdownContainer = null,
  leftIcon,
  className,
  controlBg = "var(--input-box)",
  error,
  // errorText = "An error has occurred, check your details!",
  errorText = "Required*",
  optionsWidth = "100%",

  ...props
}) => {
  let inputContainerStyleObject = Object.assign(
    {},
    error && { border: `1px solid var(--error-color) `, borderRadius: "6px" },
    leftIcon && {}
  );

  const DropdownIndicator = ({ selectProps, ...props }) => {
    const { menuIsOpen } = selectProps;
    if (!showField) {
      return (
        <components.DropdownIndicator {...props}>
          {menuIsOpen ? (
            <MdOutlineArrowDropUp size={20} color={indicatorColor} />
          ) : (
            <MdOutlineArrowDropDown size={20} color={indicatorColor} />
          )}
        </components.DropdownIndicator>
      );
    } else {
      return null;
    }
  };

  const dropDownStyle = {
    control: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#cccccc" : controlBg,
      padding: "6px 0px 6px 6px",
      // borderRadius: "8px",
      color: "var(--text-color-black)",
      // boxShadow: "0px 0px 1px 0px #32324733",
      boxShadow: "none",

      fontFamily: "Inter-regular",
      fontSize: 15,
      letterSpacing: "1.4",
      cursor: "pointer",
      border: "none",
      ...customStyle,
      minWidth: "37px",
      ":hover": {
        ...styles[":hover"],
        borderColor: "var(--primary-color)",

        // borderRadius: "8px",
      },
      ":placeholder": {
        ...styles[":placeholder"],
        color: "var(--text-color-black)",
      },
      ":active": {
        ...styles[":active"],
        borderColor: "var(--primary-color)",
      },
    }),

    menuList: (provided) => ({
      ...provided,
      borderRadius: "8px", // Set border radius as desired
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "var(--input-box)",
      color: "var(--gunmetal-blue)",
      padding: "14px 12px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      zIndex: 999999,
      maxHeight: "none", // Ensure no scroll
      overflow: "visible",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        // color: "var(--placeholder-color)",
        color: "var(--medium-gray)",
        ...placeholderStyle,
      };
    },

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected && "var(--grainsboro)",
        color: isSelected && "#000",
        padding: "8px 12px",
        fontFamily: "Inter-regular",
        fontSize: 15,
        borderRadius: "8px",

        ":active": {
          ...styles[":active"],
        },
        ":hover": {
          ...styles[":hover"],
          color: "var(--black)",
          backgroundColor: "var(--gainsboro)",
          cursor: "pointer",
        },
      };
    },

    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "var(--primary-color)",
        borderRadius: "8px",
        padding: "4px 8px",
        fontFamily: "Poppins-regular",
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        fontSize: 15,
        fontFamily: "Inter-regular",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      fontSize: 12,
      color: "#fff",
      ":hover": {
        color: "#000",
      },
    }),
  };
  return (
    <div className={`${[classes.Container].join(" ")}`}>
      <style jsx>{`
        .dropdownContainer {
          position: absolute; /* Ensure the container has a positioning context */
          z-index: 9999; /* Set a higher z-index value */
        }
        .DropdownOptionContainer__value-container {
          padding: 0;
        }
        .DropdownOptionContainer__menu {
          margin-top: 10px !important;
          width: ${optionsWidth} !important;
          margin: 0px;
          overflow: hidden;
        }

        .DropdownOptionContainer__menu > div::-webkit-scrollbar {
          display: none; /* For Chrome, Safari, and Opera */
        }

        .DropdownOptionContainer__menu > div {
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
        }
        .DropdownOptionContainer__single-value {
          color: var(--text-color-black);
        }
        .DropdownOptionContainer__menu {
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
        }
        .DropdownOptionContainer__single-value input {
          min-width: 37px !important;
        }
        .css-1mo5ddt-control {
        }
      `}</style>
      {label && (
        <div className={classes.labelWrapper}>
          <label
            htmlFor={`dropdown${label}`}
            className={`${[
              classes.label,
              labelClassName && labelClassName,
              disabled && classes.disabled,
            ].join(" ")}`}
          >
            {label}
            {isRequired && (
              <span className={[classes.staric, "error-color"].join(" ")}>
                *
              </span>
            )}
          </label>
        </div>
      )}

      <div
        className={`${[
          classes.dropdownContainer,
          leftIcon && leftIconClass,
        ].join(" ")}`}
        style={{ ...inputContainerStyleObject }}
      >
        {leftIcon && <div className={classes.leftIconBox}>{leftIcon}</div>}

        <div
          className={classes.dropdownWrapper}
          style={{ ...(showField && { width: 0 }), ...dropdownContainer }}
        >
          <ReactSelect
            inputId={`dropdown${label}`}
            value={value}
            onChange={(e) => {
              setter(e);
            }}
            className={`${[classes.reactSelect, className].join(" ")}`}
            isMulti={isMulti}
            isDisabled={disabled}
            placeholder={placeholder}
            options={options}
            styles={{
              ...dropDownStyle,
              ...style,
            }}
            isClearable={false}
            isSearchable={isSearchable}
            classNamePrefix={"DropdownOptionContainer"}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: (e) => {
                if (dropdownIndicator) return DropdownIndicator(e);
                return null;
              },
              ...Components,
            }}
            getOptionLabel={(option) => {
              return optionLabel ? option[optionLabel] : option.label;
            }}
            getOptionValue={(option) =>
              optionValue ? option[optionValue] : option.value
            }
            ref={selectRef}
            {...props}
          />
        </div>
      </div>
      {error && (
        <p className={`mt-2 ${[classes.errorText].join(" ")}`}>{errorText}</p>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  labelTwo: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired,
  setter: PropTypes.object,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  customStyle: PropTypes.object,
  style: PropTypes.object,
  Components: PropTypes.object,
  labelClassName: PropTypes.string,
};

Dropdown.defaultProps = {
  placeholder: "sdsad",
  value: "aaaa",
  disabled: false,
  isMulti: false,
  options: [],
  Components: {},
};
