import { Post } from "../../AxiosCRUDFunctions";
import { getToken } from "../../helpers/tokenStorage";
import { BaseURL, apiHeader } from "../../config";
import { toast } from "react-toastify";
export const fetchAllUserListingApi = async (
  pageNo = 1,
  limit = 10,
  search = "",
  sortField = "",
  sortDirection = "desc"
) => {
  const response = await Post(
    BaseURL("user/get-user-list"),
    {
      pageNumber: 1,
      pageSize: 10,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
    },
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    return response?.data?.body;
  }
};

export const getAllActiveUsersApi = async (params = {}) => {
  const response = await Post(
    BaseURL("user/get-users"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.message);
  }
};

export const addUserInToAPI = async (params) => {
  const response = await Post(
    BaseURL("user/add-user"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("User Created Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};

export const updateUserAPI = async (params) => {
  const response = await Post(
    BaseURL("user/update-user"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("User Updated Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};

export const getUserByIdApi = async (params) => {
  const response = await Post(
    BaseURL("user/get-user-ById"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) return response?.data?.body;
  toast.error(response?.data?.headers?.message);
  return null;
};
