import { apiHeader, BaseURL } from "../../config";
import { Post } from "../../AxiosCRUDFunctions";
import { toast } from "react-toastify";
export const loginAPI = async (params) => {
  const response = await Post(BaseURL("Auth/login"), params, apiHeader());

  if (response?.data?.headers?.error === 200) {
 
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
