import React, { useState } from "react";
import ReactDOM from "react-dom";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Button from "../Button";
import PopperPortal from "../Portals/PopperPortal";
import classes from "./PopperMenu.module.css";
import useClickOutside from "../../customHooks/useClickOutside";
const modalRoot = document.getElementById("portal-root");
const PopperMenu = ({
  lists = null,
  setter = () => {},
  children,
  listsClass = "",
  listStyle = null,
  onClick,
  className,
  component,
  componentStyle
}) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [isItemselected, setIsItemselected] = useState(-1);

  const forClickOutsideHandler = () => {
    setIsListOpen(false);
    setIsItemselected(-1);
  };
  const [ref] = useClickOutside(forClickOutsideHandler);
  return (
    <div ref={ref} className={[classes.wrapper, className].join(" ")}>
      <span
        className={`button_transparent p-0 d-flex align-items-center`}
        // ref={ref}
        onClick={() => {
          onClick && onClick();
          setIsListOpen((currentState) => !currentState);
        }}
      >
        {children ? (
          children
        ) : (
          <HiOutlineDotsVertical color="var( --slate-blue)" size={23} />
        )}
      </span>

      {lists && (
        <ul
          style={{ ...listStyle }}
          className={[
            classes.actionsListWrapper,
            !isListOpen && classes.listClose,
            listsClass,
          ].join(" ")}
        >
          {lists?.map((list, index) => (
            <li className={classes.actionItem} key={index}>
              <span
                className={[
                  classes["button-item"],
                  "button_transparent",
                  "d-block",
                  isItemselected === index && classes.selected,
                ].join(" ")}
                onClick={() => {
                  setter(list);
                  setIsItemselected(index);
                }}
              >
                {list}
              </span>
            </li>
          ))}
        </ul>
      )}
      <div
        style={{ ...componentStyle }}
        className={[
          classes.componentWrapper,
          !isListOpen && classes.listClose,
        ].join(" ")}
      >
        {component && component}
      </div>
    </div>
  );
};

export default PopperMenu;
