import { createSlice } from "@reduxjs/toolkit";
import {
  fetchDoctors,
  addDoctor,
  updateDoctor,
  getDoctorById,
  linkDoctorToMedicine,
  deleteDoctor,
  activeOrInactiveDoctor,
} from "../../../api/doctor/doctorThunk";
import { createAsyncCases } from "../../../helpers/reduxUtils";
const initialState = {
  items: [],
  status: "idle",
  loading: null,
  error: null,
  totalRowsCount: 0,
  selectedItem: null,
};
const doctorSlice = createSlice({
  name: "doctors",
  initialState,
  reducers: {
    handleSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    //fetch all doctor
    createAsyncCases(builder, fetchDoctors, {
      fulfilled: (state, action) => {
        state.items = action?.payload?.data || [];
        state.totalRowsCount = action?.payload?.pagination?.totalRowsCount;
      },
    });
    createAsyncCases(builder, addDoctor, { fulfilled(state, action) {} });
    createAsyncCases(builder, updateDoctor, { fulfilled(state, action) {} });
    createAsyncCases(builder, deleteDoctor, { fulfilled(state, action) {} });
    createAsyncCases(builder, getDoctorById, {
      fulfilled(state, action) {
        state.selectedItem = action?.payload;
      },
    });
    createAsyncCases(builder, linkDoctorToMedicine, {
      fulfilled(state, action) {},
    });
    createAsyncCases(builder, activeOrInactiveDoctor, {
      fulfilled(state, action) {},
    });
  },
});
export const { handleSelectedItem } = doctorSlice.actions;
export default doctorSlice.reducer;
