import React, { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import Loader from "./components/Loader";
// Lazy load components
const AuthLayout = lazy(() => import("./layouts/auth/AuthLayout"));
const DashboardLayout = lazy(() => import("./layouts/user/DashboardLayout"));
const ConfirmationPassword = lazy(() =>
  import("./pages/auth/ForgetPassword/Confirmation")
);
const ResetPassword = lazy(() =>
  import("./pages/auth/ForgetPassword/ResetPassword")
);
const UpdatePassword = lazy(() =>
  import("./pages/auth/ForgetPassword/UpdatePassword")
);
const Login = lazy(() => import("./pages/auth/Login"));
const Dashboard = lazy(() => import("./pages/user/Dashboard"));
const DoctorPage = lazy(() => import("./pages/user/DoctorPage"));
const DoctorDetailPage = lazy(() => import("./pages/user/DoctorDetailPage"));
// const IndividualDoctor = lazy(() =>
//   import("./pages/user/DoctorPages/IndividualDoctor")
// );
const Location = lazy(() => import("./pages/user/LocationPage"));
const MedicineDetailPage = lazy(() =>
  import("./pages/user/MedicineDetailPage")
);
const Medicine = lazy(() => import("./pages/user/MedicinePage"));
const NotFound = lazy(() => import("./pages/user/NotFound"));
const Notifications = lazy(() => import("./pages/user/Notifications"));
const PrivacyPolicy = lazy(() => import("./pages/user/PrivacyPolicy"));
const DoctorReports = lazy(() =>
  import("./pages/user/ReportPages/DoctorReports")
);
const LocationReports = lazy(() =>
  import("./pages/user/ReportPages/LocationReports")
);
const MedicineReports = lazy(() =>
  import("./pages/user/ReportPages/MedicineReports")
);
const Reports = lazy(() => import("./pages/user/ReportPages/Reports"));
const ViewReports = lazy(() =>
  import("./pages/user/ReportPages/Reports/ViewReports")
);
const Role = lazy(() => import("./pages/user/RoleManagement/Role"));
const UserList = lazy(() => import("./pages/user/RoleManagement/UserList"));
const AddOrEditRole = lazy(() =>
  import("./pages/user/RoleManagement/AddOrEditRole")
);

export default function Router() {
  const token = useSelector((state) => state?.authReducer?.token);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash.startsWith("#/")) {
      navigate(location.hash.replace("#", ""), { replace: true });
    }
  }, [location, navigate]);

  const authRoutes = useRoutes([
    {
      path: "/main_window",
      element: <AuthLayout />,
      children: [
        { path: "/main_window", element: <Login />, index: true },
        { path: "forgetPassword", element: <ResetPassword /> },
        { path: "confirmation", element: <ConfirmationPassword /> },
        { path: "updatePassword", element: <UpdatePassword /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/main_window" />,
    },
  ]);

  const userRoutes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Dashboard />, index: true },
        // { path: "doctor", element: <Doctor /> },
        { path: "doctor/:id", element: <DoctorDetailPage /> },
        { path: "medicine", element: <Medicine /> },
        {
          path: "medicine/:medicineId/:medicineDetailId",
          element: <MedicineDetailPage />,
        },
        { path: "doctor-list", element: <DoctorPage /> },
        { path: "location", element: <Location /> },
        { path: "reports", element: <Reports /> },
        { path: "reports/:id", element: <ViewReports /> },
        { path: "reports/doctor/:id", element: <DoctorReports /> },
        { path: "reports/location/:id", element: <LocationReports /> },
        { path: "reports/medicine/:id", element: <MedicineReports /> },
        { path: "privacy-policy", element: <PrivacyPolicy /> },
        { path: "role-management", element: <Role /> },
        { path: "role-management/addRole", element: <AddOrEditRole /> },
        { path: "user-list", element: <UserList /> },
        { path: "notifications", element: <Notifications /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  return (
    <Suspense fallback={<Loader />}>{token ? userRoutes : authRoutes}</Suspense>
  );
}
