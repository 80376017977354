import React, { forwardRef, useState } from "react";
import classes from "./Button.module.css";
import PopperMenu from "../PopperMenu";
import { IoIosArrowDown } from "react-icons/io";
// import PropTypes from "prop-types";
import PopoverTrigger from "../PopoverTrigger";
const Button = forwardRef(
  (
    {
      className,
      parentClass,
      children,
      label = "",
      onClick,
      disabled,
      type = "button",
      title,
      arialLabel,
      style,
      leftIcon,
      isDivider = false,
      labelClass,
      optionList = [],
      setter = () => {},
      arrowSize = 17,
      arrowColor = "#fff",
      popperClass,
      labelStyle,
      rightIcon,
      listStyle = { left: "-100px", top: "35px" },
      ...rest
    },
    ref
  ) => {
    const [pointedEle, setPointedEle] = useState(null);
    return (
      <button
        ref={ref}
        type={type}
        className={[classes["button"], className].join(" ")}
        style={style}
        onClick={isDivider ? null : onClick}
        disabled={disabled}
        title={title}
        aria-label={arialLabel}
        {...rest}
      >
        <span
          className={[
            "d-flex align-items-center  g-8 text-center w-100",
            parentClass,
          ].join(" ")}
          onClick={isDivider ? onClick : null}
        >
          {leftIcon && (
            <span className="d-flex align-items-center">{leftIcon}</span>
          )}
          <span
            style={{ display: "block", width: "100%", ...labelStyle }}
            className={[
              "d-flex align-items-center text-center w-100 justify-content-center",
              classes.label,
              labelClass,
            ].join(" ")}
          >
            {label || children}
          </span>
          {rightIcon && (
            <span className="d-flex align-items-center">{rightIcon}</span>
          )}
        </span>

        {/* divider section */}
        {isDivider && (
          <>
            {/* <PopperMenu
            className={popperClass}
           setter={setter}
             lists={optionList}
            listStyle={listStyle}
           > */}
            <PopoverTrigger
              isIcon={false}
              lists={optionList}
              setter={setter}
              onClick={() => {}}
              anchorEle={pointedEle}
              setAnchorEle={setPointedEle}
            />
            <IoIosArrowDown
              color={arrowColor}
              size={arrowSize}
              onClick={(event) => {
                setPointedEle(event.currentTarget);
              }}
            />
            {/* </PopperMenu> */}
          </>
        )}
      </button>
    );
  }
);

// Button.propTypes = {
//   disabled: PropTypes.bool,
//   label: PropTypes.string.isRequired,
//   customStyle: PropTypes.object,
//   className: PropTypes.string,
//   onClick: PropTypes.func,
//   type: PropTypes.string,
//   title: PropTypes.string,
// };

export default Button;
