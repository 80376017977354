import React from "react";
import "./ToggleMode.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faMoon, faStar } from "@fortawesome/free-solid-svg-icons";

const ToggleMode = ({ id, setter, value }) => {
  const handleCheckboxChange = () => {
    setter(!value);
  };
  return (
    <label id="theme-toggle-button">
      <input
        type="checkbox"
        id={id}
        checked={value}
        className="toggle"
        onChange={handleCheckboxChange}
      />
      <svg
        viewBox="0 0 69.667 44"
        xlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          transform="translate(3.5 3.5)"
          data-name="Component 15 – 1"
          id="Component_15_1"
        >
          <g
            filter="url(#container)"
            transform="matrix(1, 0, 0, 1, -3.5, -3.5)"
          >
            <rect
              fill="#7895FF" // Updated color here
              transform="translate(3.5 3.5)"
              rx="17.5"
              height="35"
              width="60.667"
              data-name="container"
              id="container"
            ></rect>
          </g>

          <g transform="translate(2.333 2.333)" id="button">
            <g data-name="sun" id="sun">
              <g
                filter="url(#sun-outer)"
                transform="matrix(1, 0, 0, 1, -5.83, -5.83)"
              >
                <circle
                  fill="#f8e664"
                  transform="translate(5.83 5.83)"
                  r="10.167"
                  cy="15.167"
                  cx="15.167"
                  data-name="sun-outer"
                  id="sun-outer-2"
                ></circle>
              </g>
              <g
                filter="url(#sun)"
                transform="matrix(1, 0, 0, 1, -5.83, -5.83)"
              >
                <path
                  fill="#fff"
                  transform="translate(9.33 9.33)"
                  d="M11.667,0A11.667,11.667,0,1,1,0,11.667,11.667,11.667,0,0,1,11.667,0Z"
                  data-name="sun"
                  id="sun-3"
                ></path>
              </g>
              {/* <circle
                fill="#fcf4b9"
                transform="translate(8.167 8.167)"
                r="7"
                cy="7"
                cx="7"
                id="sun-inner"
              ></circle> */}
            </g>

            <g data-name="moon" id="moon">
              <g filter="url(#moon)" transform="matrix(1, 0, 0, 1, -25, -5.83)">
                <FontAwesomeIcon
                  color="#ffff"
                  width="20px"
                  icon={faMoon}
                  flip="vertical"
                />
                {/* <circle
                  fill="#cce6ee"
                  transform="translate(31.5 5.83)"
                  r="12.167"
                  cy="15.167"
                  cx="15.167"
                  data-name="moon"
                  id="moon-3"
                ></circle> */}
              </g>
              {/* <g
                fill="#a6cad0"
                transform="translate(-24.415 -1.009)"
                id="patches"
              >
                <circle
                  transform="translate(43.009 4.496)"
                  r="1"
                  cy="2"
                  cx="2"
                ></circle>
                <circle
                  transform="translate(39.366 17.952)"
                  r="2"
                  cy="2"
                  cx="2"
                  data-name="patch"
                ></circle>
                <circle
                  transform="translate(33.016 8.044)"
                  r="1"
                  cy="1"
                  cx="1"
                  data-name="patch"
                ></circle>

                <circle
                  transform="translate(33.016 22.503)"
                  r="1"
                  cy="1"
                  cx="1"
                  data-name="patch"
                ></circle>
              </g> */}
            </g>
          </g>

          <g
            filter="url(#cloud)"
            id="cloud"
            transform={"matrix(1, 0, 0, 1, 34, -1)"}
          >
            <FontAwesomeIcon width="3px" color="#fff" icon={faCircle} />
          </g>
          <g
            filter="url(#cloud)"
            id="cloud"
            transform={"matrix(1, 0, 0, 1, 37, -12)"}
          >
            <FontAwesomeIcon width="1.5px" color="#fff" icon={faCircle} />
          </g>
          <g
            filter="url(#cloud)"
            id="cloud"
            transform={"matrix(1, 0, 0, 1, 43, 4)"}
          >
            <FontAwesomeIcon width="2px" color="#fff" icon={faCircle} />
          </g>
          <g
            filter="url(#cloud)"
            id="cloud"
            transform={"matrix(1, 0, 0, 1, 45, -8)"}
          >
            <FontAwesomeIcon width="6px" color="#fff" icon={faCircle} />
          </g>

          <g fill="#def8ff" transform="translate(12 -12)" id="stars">
            <FontAwesomeIcon icon={faStar} color="#fff" width="5px" />
          </g>
          <g fill="#def8ff" transform="translate(22 3)" id="stars">
            <FontAwesomeIcon icon={faStar} color="#fff" width="4px" />
          </g>
          <g id="stars" transform={"matrix(1, 0, 0, 1, 10, -3)"}>
            <FontAwesomeIcon width="1.5px" color="#fff" icon={faCircle} />
          </g>
          <g id="stars" transform={"matrix(1, 0, 0, 1, 25, -12)"}>
            <FontAwesomeIcon width="1.5px" color="#fff" icon={faCircle} />
          </g>
          <g id="stars" transform={"matrix(1, 0, 0, 1, 20, -9)"}>
            <FontAwesomeIcon width="1.5px" color="#fff" icon={faCircle} />
          </g>
        </g>
      </svg>
    </label>
  );
};

export default ToggleMode;
