// src/features/medicine/medicineSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMedicines,
  addMedicine,
  updateMedicine,
  deleteMedicine,
  getMedicineById,
  linkDoctor,
  modifyTarget,
  closeAndCreateMedicineTarget,
  getAllMedicineUnitsAndTypes,
} from "../../../api/medicine/medicineThunk";
import { createAsyncCases } from "../../../helpers/reduxUtils";
const initialState = {
  items: [],
  status: "idle",
  loading: null,
  error: null,
  totalRowsCount: 0,
  selectedItem: null,
  medicineUnits: [],
  medicineTypes: [],
};
const medicineSlice = createSlice({
  name: "medicines",
  initialState,
  reducers: {
    handleSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get All Medicine
    createAsyncCases(builder, fetchMedicines, {
      fulfilled: (state, action) => {
        state.items = action?.payload?.data || [];
        state.totalRowsCount = action?.payload?.pagination?.totalRowsCount;
      },
    });
    //Add Medicine
    createAsyncCases(builder, addMedicine, {
      fulfilled: (state, action) => {
        // state.items.push(action.payload);
      },
    });
    //Update Medicine
    createAsyncCases(builder, updateMedicine, {
      fulfilled: (state, action) => {
        // const index = state.items.findIndex(
        //   (medicine) => medicine.id === action.payload.id
        // );
        // if (index !== -1) state.items[index] = action.payload;
      },
    });
    //Delete Medicine
    createAsyncCases(builder, deleteMedicine, {
      fulfilled: (state, action) => {
        // state.items = state.items.filter(
        //   (medicine) => medicine.id !== action.payload
        // );
      },
    });
    // Get Medicine By Id
    createAsyncCases(builder, getMedicineById, {
      fulfilled: (state, action) => {
        state.selectedItem = action.payload;
      },
    });
    //handle link doctor to medicine
    createAsyncCases(builder, linkDoctor, {
      fulfilled: (state, action) => {},
    });

    createAsyncCases(builder, modifyTarget, {
      fulfilled: (state, action) => {},
    });
    createAsyncCases(builder, closeAndCreateMedicineTarget, {
      fulfilled(state, action) {},
    });
    createAsyncCases(builder, getAllMedicineUnitsAndTypes, {
      fulfilled: (state, action) => {
        state.medicineUnits = action?.payload[0];
        state.medicineTypes = action?.payload[1];
      },
    });
  },
});
export const { handleSelectedItem } = medicineSlice.actions;
export default medicineSlice.reducer;
