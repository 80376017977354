import moment from "moment";
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const regex = /^\+92\d{10}$/;

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

/**
 *
 * @param {Array} list
 * @param {String} labelField
 * @param {String} valueField
 * @param {String} label
 * @param {String} value
 * @param {Object} spread
 * @returns
 */
//transform obj according to react-select dropdown
export const transformedArrayOfObj = (
  list,
  labelField,
  valueField,
  label = "label",
  value = "value",
  spread = false
) =>
  list.map((data) => {
    return {
      [label]: data[labelField],
      [value]: data[valueField],
      ...(spread && data),
    };
  });

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export var recordsLimit = 10;

export const dateDifference = (date) => {
  let duration = moment.duration(moment().diff(date));
  let daysDiff = duration.asDays();
  return daysDiff;
};

export const formatNumberWithcommas = (number) => {
  return +number.toLocaleString("en-US");
};
export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const transformIntoObj = (
  list,
  id,
  label = "label",
  value = "value"
) => {
  const eleFound = list.find((element) => element[value] === id);
  return { label: eleFound[label], value: eleFound[value] };
};
export const dateFormatter = (date, format = "DD MMM YYYY") =>
  moment(date).format(format);
export const daysRemaining = (startDate, endDate) => {
  // Parse the input dates using moment
  const start = moment(startDate?.trim(), "YYYY-MM-DD", true);
  const end = moment(endDate?.trim(), "YYYY-MM-DD", true);
  // Check if both dates are valid
  if (!start.isValid() || !end.isValid()) {
    return "";
  }
  // Calculate the difference in days
  const diffDays = end.diff(start, "days");
  return diffDays;
};

export const numberFormatter = (number) => {
  const result = Number(number);
  return result.toLocaleString();
};

export const setValueIntoSetter = (setter, name, value) => {
  setter((prev) => {
    return { ...prev, [name]: value };
  });
};

export const validatePhoneNumber = (phoneNumber) => regex.test(phoneNumber);
export const handleCharCount = (char, limit) => {
  return `${char < limit ? char : limit}/${limit}`;
};

export const isTruthy = (value, replace = 0) => {
  return value ? value : replace;
};
