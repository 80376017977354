import { getAllRelatedCitiesApi, getAllProvinceApi } from "./addressApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getAllRelatedCities = createAsyncThunk(
  "address/get-cities-by-stateId",
  getAllRelatedCitiesApi
);

export const getAllProvinces = createAsyncThunk(
  "address/get-states-by-countryId",
  getAllProvinceApi
);
