import { createSlice } from "@reduxjs/toolkit";
import { login } from "../../../api/auth/authThunk";
import { createAsyncCases } from "../../../helpers/reduxUtils";
const initialState = {
  status: "idle",
  token: "",
  isLogin: false,
  loading: null,
  error: null,
  user: null,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.isLogin = false;
      state.user = null;
      state.menu = null;
    },
  },
  extraReducers: (builder) => {
    createAsyncCases(builder, login, {
      fulfilled(state, action) {
        state.token = action.payload?.accessToken;
        state.isLogin = true;
      },
    });
  },
});

export default authSlice?.reducer;

export const { logout } = authSlice.actions;
