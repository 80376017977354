import { toast } from "react-toastify";
import { Post, Get } from "../../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../../config";
import { getToken } from "../../helpers/tokenStorage";
export const fetchRoleListApi = async (
  pageNo = 1,
  limit = 10,
  search = "",
  sortField = "",
  sortDirection = "desc"
) => {
  const response = await Post(
    BaseURL("Role/get-role-list"),
    {
      pageNumber: 0,
      pageSize: 10,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
    },
    apiHeader(getToken())
  );
  return response?.data?.body;
};
