import { toast } from "react-toastify";
// src/utils/reduxUtils.js
// export const createAsyncCases = (builder, asyncThunk, cases) => {
//   builder
//     .addCase(asyncThunk.pending, cases.pending)
//     .addCase(asyncThunk.fulfilled, cases.fulfilled)
//     .addCase(asyncThunk.rejected, cases.rejected);
// };

// src/helpers/reduxUtils.js
export const createAsyncCases = (
  builder,
  asyncThunk,
  cases = { fulfilled(state, action) {} }
) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.status = false;
      state.loading = true;
      if (cases.pending) cases.pending(state);
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.status = true;
      state.loading = false;
      if (cases.fulfilled) cases.fulfilled(state, action);
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.status = false;
      state.loading = false;
      state.error = action?.error?.message;
      toast.error(action?.error?.message);
      if (cases.rejected) cases.rejected(state, action);
    });
};
