import React from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../Button";
import classes from "./Pagination.module.css";
const Pagination = ({ page, setPage, limit, totalCount }) => {
  const totalNoOfPages = Math.ceil(totalCount / limit);
  return (
    <div className={["flex_1rem_gap", classes.wrapper].join(" ")}>
      <Button
        onClick={() => {
          if (page > 1) {
            setPage(+page - 1);
          } else {
            toast.warn("Value should be greater than 0 ");
          }
        }}
        className={["align_grid_1rem_gap", "button_transparent"].join(" ")}
      >
        <MdArrowLeft size={23} color="var(--steel-gray)" />
        <span className={classes["indicator__name"]}>Back</span>
      </Button>
      <div className="flex_1rem_gap">
        <input
          type={"number"}
          className={[classes.inputBox, "medium-gray"].join(" ")}
          value={page}
          onChange={(e) => {
            const value = +e?.target?.value;
            if (value < 0) {
              toast.warn("Page Should be greater than 0");
              return 0;
            } else if (value > totalNoOfPages) {
              toast.warn("Page is greater than total no of pages");
              return 0;
            }

            setPage(value);
          }}
          style={{
            background: "var(--white-color)",
            color: "var(--medium-gray)",
            textAlign: "center",
          }}
          placeholder=""
          min="1"
          max={totalNoOfPages}
        />
        <p className={[classes["indicator__outof"], "font-medium"].join(" ")}>
          of {totalNoOfPages}
        </p>
      </div>
      <Button
        onClick={() => {
          if (page < totalNoOfPages) {
            setPage(+page + 1);
          } else {
            toast.warn("Value should be less than total pages ");
          }
        }}
        className={["align_grid_1rem_gap", "button_transparent"].join(" ")}
      >
        <span className={classes["indicator__name"]}>Next</span>
        <MdArrowRight size={23} color="var(--steel-gray)" />
      </Button>
    </div>
  );
};

export default Pagination;
