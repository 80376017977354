import React from "react";
import Classes from "./ModalAction.module.css";
import Button from "../../components/Button";
const ModalAction = ({ controls, className }) => {
  const { onCancel, onConfirm } = controls;
  return (
    <div
      className={[
       
        Classes.actionWrapper,
     
        className,
      ].join(" ")}
    >
      <Button
        className={["cancel", "btn-half", "fw-600 f14 py-16"].join(" ")}
        label={"Cancel"}
        labelClass={Classes.cancelLabel}
        onClick={() => {
          if (typeof onCancel === "function") onCancel();
        }}
      />
      <Button
        label={"Confirm"}
        className={["fw-600", "f14", "btn-half py-16"].join(" ")}
        labelClass={Classes.label}
        onClick={() => {
          if (typeof onConfirm == "function") onConfirm();
        }}
      />
    </div>
  );
};

export default ModalAction;
