import React, { useState } from "react";
import { MdOutlineLocationSearching } from "react-icons/md";

import useClickOutside from "../../../customHooks/useClickOutside";
import FilterActionButton from "../../FilterActionButton";
import classes from "./MedicineEstimatedValue.module.css";
import { Row, Col } from "react-bootstrap";
import { Dropdown } from "../../Dropdown";
import { Input } from "../../Input";
import { BsGraphUpArrow } from "react-icons/bs";
const options = [
  { label: "All Value", value: "all" },
  { label: "Rs 10,000 30,000", value: "Rs 10,000 30,000" },
  { label: "Rs 30,000 60,000", value: "Rs 30,000 60,000" },
  { label: "Rs.0-Rs-10,000", value: "Rs.0-Rs-10,000" },
  { label: "Rs 60,000 above", value: "Rs 60,000 above" },
];
const PopperMedicineTarget = ({
  lists = null,
  setter=()=>{
    
  },
  children,
  listsClass = "",
  listStyle = null,
}) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [minTarget, setMinTarget] = useState("");
  const [maxTarget, setMaxTarget] = useState("");

  const [ref] = useClickOutside(() => {
    setIsListOpen(false);
  });

  return (
    <div className={classes.wrapper} ref={ref}>
      <span>
        <FilterActionButton
          className={classes.iconHolder}
          onClick={() => {
            setIsListOpen((currentState) => !currentState);
          }}
        >
          <BsGraphUpArrow />
          {/* <MdOutlineLocationSearching /> */}
        </FilterActionButton>
      </span>

      <div
        style={{ ...listStyle }}
        className={[
          classes.actionsListWrapper,
          !isListOpen && classes.listClose,
          listsClass,
        ].join(" ")}
      >
        <Row>
          <Col sm={4}>
            <Dropdown options={options} placeholder={"All Target"} />
          </Col>
          <Col sm={4}>
            <Input
              setter={setMinTarget}
              value={minTarget}
              parentCustomStyle={{ height: "100%" }}
              customStyle={{ height: "100%" }}
            />
          </Col>
          <Col sm={4}>
            <Input
              setter={setMaxTarget}
              value={maxTarget}
              parentCustomStyle={{ height: "100%" }}
              customStyle={{ height: "100%" }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PopperMedicineTarget;
