import {
  fetchDoctorList,
  getDoctorByIdAPI,
  linkDoctorToMedicineInAPI,
  addDoctorToAPI,
  updateDoctorInAPI,
  activeOrInactiveInAPI,
  deleteDoctorFromAPI,
} from "./doctorApi";

import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchDoctors = createAsyncThunk(
  "doctor/get-doctor-list",
  fetchDoctorList
);
export const addDoctor = createAsyncThunk("doctor/add-doctor", addDoctorToAPI);
export const updateDoctor = createAsyncThunk(
  "doctor/update-doctor",
  updateDoctorInAPI
);
export const deleteDoctor = createAsyncThunk(
  "doctor/delete-doctor",
  deleteDoctorFromAPI
);
export const getDoctorById = createAsyncThunk(
  "doctor/get-doctor-ById",
  getDoctorByIdAPI
);
export const activeOrInactiveDoctor = createAsyncThunk(
  "doctor/activeinactive-doctor",
  activeOrInactiveInAPI
);

export const linkDoctorToMedicine = createAsyncThunk(
  "doctor/link-medicine-target",
  linkDoctorToMedicineInAPI
);
