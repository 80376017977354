// src/features/medicine/medicineSlice.js
import { createSlice } from "@reduxjs/toolkit";

import {
  fetchAllUserListing,
  addUser,
  updateUser,
  getUserById,
  getAllActiveUsers,
} from "../../../api/user/userThunk";
import { createAsyncCases } from "../../../helpers/reduxUtils";

const initialState = {
  items: [],
  status: "idle",
  loading: null,
  error: null,
  totalRowsCount: 0,
  selectedItem: null,
  allActiveUsers: [],
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get All Medicine
    createAsyncCases(builder, fetchAllUserListing, {
      fulfilled: (state, action) => {
        state.items = action?.payload?.data;
        state.totalRowsCount = action?.payload?.pagination?.totalRowsCount;
      },
    });
    //Add Medicine
    createAsyncCases(builder, addUser, {
      fulfilled: (state, action) => {
        // state.items.push(action.payload);
      },
    });
    //Update Medicine
    createAsyncCases(builder, updateUser, {
      fulfilled: (state, action) => {
        // const index = state.items.findIndex(
        //   (medicine) => medicine.id === action.payload.id
        // );
        // if (index !== -1) state.items[index] = action.payload;
      },
    });
    createAsyncCases(builder, getUserById, {
      fulfilled: (state, action) => {
        state.selectedItem = action.payload;
        // const index = state.items.findIndex(
        //   (medicine) => medicine.id === action.payload.id
        // );
        // if (index !== -1) state.items[index] = action.payload;
      },
    });
    createAsyncCases(builder, getAllActiveUsers, {
      fulfilled(state, action) {
        state.allActiveUsers = action.payload;
      },
    });
  },
});
export const { handleSelectedItem } = userSlice.actions;
export default userSlice.reducer;
