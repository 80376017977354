import React from "react";
export const DoctorIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7014 5.21603H5.68018V6.64901H15.7014V5.21603Z"
        fill="#6B6F80"
      />
      <path
        d="M11.1935 13.9667H10.1904C7.30537 13.9667 4.96484 11.6262 4.96484 8.74113V2.91371L5.08903 2.7322C5.16546 2.61756 7.03788 0 10.9165 0C14.795 0 16.2758 2.70354 16.3426 2.81818L16.4191 2.97103V8.75069C16.4191 11.6357 14.069 13.9763 11.1935 13.9763V13.9667ZM6.39782 3.38182V8.74113C6.39782 10.8333 8.09828 12.5337 10.1904 12.5337H11.1935C13.2856 12.5337 14.9861 10.8333 14.9861 8.74113V3.33405C14.69 2.88505 13.534 1.42342 10.9165 1.42342C8.29889 1.42342 6.82771 2.88505 6.39782 3.38182Z"
        fill="#6B6F80"
      />
      <path
        d="M20.7273 24H0V19.2752C0 15.9477 2.74597 13.2334 6.24469 13.0927C6.64757 13.0826 7.01865 13.2334 7.2837 13.5149L10.4007 16.8726L13.9419 13.4848C14.2175 13.2234 14.5886 13.0726 14.9809 13.0927C18.204 13.2435 20.7273 15.7567 20.7273 18.8128V24ZM1.59033 22.4921H19.1369V18.8128C19.1369 16.5911 17.3346 14.7615 15.0127 14.6006L10.3371 19.0842L6.17047 14.6107C3.59414 14.7816 1.59033 16.8022 1.59033 19.2752V22.4921Z"
        fill="#6B6F80"
      />
      <path
        d="M6.20716 14.2909H4.5708V17.6618H6.20716V14.2909Z"
        fill="#6B6F80"
      />
      <path
        d="M7.69081 21.7964C7.24354 21.7964 6.87263 21.4255 6.87263 20.9782V19.3964C6.87263 18.6218 6.2399 17.9782 5.45445 17.9782C4.66899 17.9782 4.03627 18.6109 4.03627 19.3964V20.9782C4.03627 21.4255 3.66536 21.7964 3.21808 21.7964C2.77081 21.7964 2.3999 21.4255 2.3999 20.9782V19.3964C2.41081 17.7164 3.77445 16.3527 5.46536 16.3527C7.15627 16.3527 8.5199 17.7164 8.5199 19.4073V20.9891C8.5199 21.4364 8.14899 21.8073 7.70172 21.8073L7.69081 21.7964Z"
        fill="#6B6F80"
      />
      <path
        d="M15.7598 14.5V16.64"
        stroke="#6B6F80"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M15.76 19.16C16.46 19.16 17.02 18.6 17.02 17.9C17.02 17.2 16.46 16.64 15.76 16.64C15.06 16.64 14.5 17.2 14.5 17.9C14.5 18.6 15.06 19.16 15.76 19.16Z"
        stroke="#6B6F80"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export const CapsuleIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3892_6613)">
        <path
          d="M8 1.87258C8.87885 0.993733 10.0708 0.5 11.3137 0.5C12.5566 0.5 13.7486 0.993733 14.6274 1.87258C15.5063 2.75143 16 3.94341 16 5.18629C16 6.42917 15.5063 7.62115 14.6274 8.5L8 15.1274C7.56484 15.5626 7.04822 15.9078 6.47966 16.1433C5.91109 16.3788 5.3017 16.5 4.68629 16.5C3.44341 16.5 2.25143 16.0063 1.37258 15.1274C0.493733 14.2486 0 13.0566 0 11.8137C0 10.5708 0.493733 9.37885 1.37258 8.5L8 1.87258ZM10.8411 10.3932L13.6795 7.55476C14.3028 6.92619 14.6517 6.07632 14.6499 5.19114C14.648 4.30595 14.2957 3.45752 13.6698 2.83151C13.044 2.2055 12.1957 1.85288 11.3105 1.85082C10.4253 1.84876 9.57533 2.19742 8.94658 2.8205L6.10683 5.65891L10.8411 10.3932ZM7.80452 12.3211C7.86668 12.2589 7.91596 12.185 7.94957 12.1037C7.98317 12.0224 8.00043 11.9353 8.00037 11.8474C8.00031 11.7594 7.98292 11.6723 7.9492 11.5911C7.91549 11.5098 7.8661 11.436 7.80385 11.3739C7.74161 11.3117 7.66774 11.2624 7.58645 11.2288C7.50516 11.1952 7.41805 11.178 7.33009 11.178C7.24213 11.1781 7.15504 11.1955 7.0738 11.2292C6.99256 11.2629 6.91876 11.3123 6.8566 11.3746L4.84829 13.3829C4.78614 13.4451 4.73686 13.519 4.70325 13.6003C4.66965 13.6816 4.65239 13.7687 4.65245 13.8566C4.65257 14.0343 4.72326 14.2046 4.84896 14.3301C4.97467 14.4556 5.14508 14.5261 5.32273 14.526C5.50037 14.5258 5.67069 14.4552 5.79622 14.3294L7.80452 12.3211Z"
          fill="#A0AEC0"
        />
      </g>
      <defs>
        <clipPath id="clip0_3892_6613">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UserIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33333 16.5C1.33333 16.5 0 16.5 0 15.1667C0 13.8333 1.33333 9.83333 8 9.83333C14.6667 9.83333 16 13.8333 16 15.1667C16 16.5 14.6667 16.5 14.6667 16.5H1.33333Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 8.5C10.2091 8.5 12 6.70914 12 4.5C12 2.29086 10.2091 0.5 8 0.5C5.79086 0.5 4 2.29086 4 4.5C4 6.70914 5.79086 8.5 8 8.5Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const LocationIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 16.5C8 16.5 14 10.8137 14 6.5C14 3.18629 11.3137 0.5 8 0.5C4.68629 0.5 2 3.18629 2 6.5C2 10.8137 8 16.5 8 16.5ZM8 9.5C9.65685 9.5 11 8.15685 11 6.5C11 4.84315 9.65685 3.5 8 3.5C6.34315 3.5 5 4.84315 5 6.5C5 8.15685 6.34315 9.5 8 9.5Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const GroupUser = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7 14.5C7 14.5 6 14.5 6 13.5C6 12.5 7 9.5 11 9.5C15 9.5 16 12.5 16 13.5C16 14.5 15 14.5 15 14.5H7Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 8.5C12.6569 8.5 14 7.15685 14 5.5C14 3.84315 12.6569 2.5 11 2.5C9.34315 2.5 8 3.84315 8 5.5C8 7.15685 9.34315 8.5 11 8.5Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.21636 14.5C5.07556 14.2159 5 13.8791 5 13.5C5 12.1445 5.67905 10.7506 6.93593 9.77997C6.3861 9.60409 5.7451 9.5 5 9.5C1 9.5 0 12.5 0 13.5C0 14.5 1 14.5 1 14.5H5.21636Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.5 8.5C5.88071 8.5 7 7.38071 7 6C7 4.61929 5.88071 3.5 4.5 3.5C3.11929 3.5 2 4.61929 2 6C2 7.38071 3.11929 8.5 4.5 8.5Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const BellIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16.5C9.10457 16.5 10 15.6046 10 14.5H6C6 15.6046 6.89543 16.5 8 16.5Z"
        fill="#A0AEC0"
      />
      <path
        d="M8.99516 1.59903C8.99836 1.56646 9 1.53342 9 1.5C9 0.947715 8.55228 0.5 8 0.5C7.44772 0.5 7 0.947715 7 1.5C7 1.53342 7.00164 1.56646 7.00484 1.59904C4.7202 2.06045 3.00002 4.07934 3.00002 6.5C3.00002 7.5976 2.5 12.5 1 13.5H15C13.5 12.5 13 7.5976 13 6.5C13 4.07932 11.2798 2.06042 8.99516 1.59903Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const HomeIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2.79288L14 8.79288V13C14 13.8284 13.3284 14.5 12.5 14.5H3.5C2.67157 14.5 2 13.8284 2 13V8.79288L8 2.79288Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 1.99999V5.49999L11 3.49999V1.99999C11 1.72384 11.2239 1.49999 11.5 1.49999H12.5C12.7761 1.49999 13 1.72384 13 1.99999Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.29289 0.999986C7.68342 0.60946 8.31658 0.609462 8.70711 0.999986L15.3536 7.64643C15.5488 7.84169 15.5488 8.15828 15.3536 8.35354C15.1583 8.5488 14.8417 8.5488 14.6464 8.35354L8 1.70709L1.35355 8.35354C1.15829 8.5488 0.841709 8.5488 0.646447 8.35354C0.451184 8.15828 0.451184 7.84169 0.646447 7.64643L7.29289 0.999986Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const ListIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 12C5 11.7239 5.22386 11.5 5.5 11.5H14.5C14.7761 11.5 15 11.7239 15 12C15 12.2761 14.7761 12.5 14.5 12.5H5.5C5.22386 12.5 5 12.2761 5 12Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 8C5 7.72386 5.22386 7.5 5.5 7.5H14.5C14.7761 7.5 15 7.72386 15 8C15 8.27614 14.7761 8.5 14.5 8.5H5.5C5.22386 8.5 5 8.27614 5 8Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 4C5 3.72386 5.22386 3.5 5.5 3.5H14.5C14.7761 3.5 15 3.72386 15 4C15 4.27614 14.7761 4.5 14.5 4.5H5.5C5.22386 4.5 5 4.27614 5 4Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 5C2.55228 5 3 4.55228 3 4C3 3.44772 2.55228 3 2 3C1.44772 3 1 3.44772 1 4C1 4.55228 1.44772 5 2 5Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8C1 8.55228 1.44772 9 2 9Z"
        fill="#A0AEC0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 13C2.55228 13 3 12.5523 3 12C3 11.4477 2.55228 11 2 11C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const MilitaryIcon = () => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 0H10.75V7.85C10.75 8.23333 10.6667 8.575 10.5 8.875C10.3333 9.175 10.1 9.41667 9.8 9.6L6 12L6.5 14H9L7 15.5L8 18L6 16.2L4 18L5 15.5L3 14H5.25L5.75 12L1.7 9.6C1.4 9.41667 1.16667 9.175 1 8.875C0.833333 8.575 0.75 8.23333 0.75 7.85V0ZM2.25 1V8.5L5.25 10.5V5.5V1H2.25ZM9.25 1H6.25V10.5L9.25 8.5V1Z"
        fill="#B5B5BD"
      />
    </svg>
  );
};

export const RoleIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3538_37019"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="17"
      >
        <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3538_37019)">
        <path
          d="M8.00016 11.1668C8.75572 11.1668 9.59461 11.0168 10.5168 10.7168C11.4391 10.4168 12.8224 9.88905 14.6668 9.1335V8.8835C14.6668 8.31683 14.4391 7.86127 13.9835 7.51683C13.5279 7.17239 13.0279 7.07794 12.4835 7.2335C12.2946 7.28905 12.1029 7.35572 11.9085 7.4335C11.7141 7.51127 11.4557 7.61127 11.1335 7.7335C10.4557 8.02239 9.8835 8.22238 9.41683 8.3335C8.95016 8.44461 8.47794 8.50016 8.00016 8.50016C7.50016 8.50016 7.01683 8.44183 6.55016 8.32516C6.0835 8.2085 5.49461 8.00572 4.7835 7.71683C4.51683 7.60572 4.28627 7.51405 4.09183 7.44183C3.89739 7.36961 3.71127 7.30572 3.5335 7.25016C2.98905 7.07238 2.48627 7.15572 2.02516 7.50016C1.56405 7.84461 1.3335 8.30572 1.3335 8.8835V9.10016C2.78905 9.76683 4.06683 10.2779 5.16683 10.6335C6.26683 10.9891 7.21127 11.1668 8.00016 11.1668ZM8.00016 15.1668C9.5335 15.1668 10.8807 14.7474 12.0418 13.9085C13.2029 13.0696 13.9891 11.9946 14.4002 10.6835C12.9446 11.3057 11.7224 11.7641 10.7335 12.0585C9.74461 12.3529 8.8335 12.5002 8.00016 12.5002C7.15572 12.5002 6.19183 12.3418 5.1085 12.0252C4.02516 11.7085 2.84461 11.2557 1.56683 10.6668C1.94461 12.0335 2.70572 13.1252 3.85016 13.9418C4.99461 14.7585 6.37794 15.1668 8.00016 15.1668ZM8.00016 7.16683C7.26683 7.16683 6.63905 6.90572 6.11683 6.3835C5.59461 5.86127 5.3335 5.2335 5.3335 4.50016C5.3335 3.76683 5.59461 3.13905 6.11683 2.61683C6.63905 2.09461 7.26683 1.8335 8.00016 1.8335C8.7335 1.8335 9.36127 2.09461 9.8835 2.61683C10.4057 3.13905 10.6668 3.76683 10.6668 4.50016C10.6668 5.2335 10.4057 5.86127 9.8835 6.3835C9.36127 6.90572 8.7335 7.16683 8.00016 7.16683Z"
          fill="#A0AEC0"
        />
      </g>
    </svg>
  );
};

export const UserList = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 14.5C1 14.5 0 14.5 0 13.5C0 12.5 1 9.5 6 9.5C11 9.5 12 12.5 12 13.5C12 14.5 11 14.5 11 14.5H1Z"
        fill="#A0AEC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.5C7.65685 8.5 9 7.15685 9 5.5C9 3.84315 7.65685 2.5 6 2.5C4.34315 2.5 3 3.84315 3 5.5C3 7.15685 4.34315 8.5 6 8.5Z"
        fill="#A0AEC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 5.5C13.7761 5.5 14 5.72386 14 6V7.5H15.5C15.7761 7.5 16 7.72386 16 8C16 8.27614 15.7761 8.5 15.5 8.5H14V10C14 10.2761 13.7761 10.5 13.5 10.5C13.2239 10.5 13 10.2761 13 10V8.5H11.5C11.2239 8.5 11 8.27614 11 8C11 7.72386 11.2239 7.5 11.5 7.5H13V6C13 5.72386 13.2239 5.5 13.5 5.5Z"
        fill="#A0AEC0"
      />
    </svg>
  );
};

export const ToggleIcon = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 7 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6.39544C1 6.59268 1.16789 6.75258 1.375 6.75258H5.71967L4 8.39032C3.85355 8.52979 3.85355 8.75592 4 8.89539C4.14645 9.03487 4.38388 9.03487 4.53033 8.89539L6.89017 6.64798C7.03661 6.5085 7.03661 6.28237 6.89017 6.1429L4.53033 4.10461C4.38388 3.96513 4.14645 3.96513 4 4.10461C3.85355 4.24408 3.85355 4.47021 4 4.60968L5.71967 6.03829H1.375C1.16789 6.03829 1 6.19819 1 6.39544Z"
        fill="var(--slate-gray)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.60456C6 2.40732 5.83211 2.24742 5.625 2.24742L1.28033 2.24742L3 0.609684C3.14645 0.470211 3.14645 0.244079 3 0.104605C2.85355 -0.0348683 2.61612 -0.0348683 2.46967 0.104605L0.109835 2.35202C-0.0366115 2.4915 -0.0366115 2.71763 0.109835 2.8571L2.46967 4.89539C2.61612 5.03487 2.85355 5.03487 3 4.89539C3.14645 4.75592 3.14645 4.52979 3 4.39032L1.28033 2.96171L5.625 2.96171C5.83211 2.96171 6 2.80181 6 2.60456Z"
        fill="var(--slate-gray)"
      />
    </svg>
  );
};

export const ShortLogo = () => (
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6555 0.325498L9.65471 8.90467C9.38926 9.15761 9.29905 9.54222 9.4257 9.88698L14.1429 22.8961C14.2418 23.1681 14.5003 23.35 14.7901 23.35H27.2659C28.1351 23.3604 28.744 22.4959 28.4387 21.6833L20.5743 0.763812C20.2776 -0.0261927 19.2679 -0.256611 18.6555 0.325498Z"
      fill="#38DBFF"
    />
    <g style={{ mixBlendMode: "multiply" }}>
      <path
        d="M0.0899396 8.92714L4.84362 22.4196C5.03966 22.9774 5.56708 23.3499 6.15869 23.3499H14.986C15.8291 23.3499 16.5509 22.7418 16.6914 21.9102L18.4385 12.4059C18.5929 11.5622 18.0585 10.7445 17.2223 10.5453L1.90466 6.91574C0.716239 6.63162 -0.316032 7.77331 0.0899396 8.9254V8.92714Z"
        fill="#4C6FFF"
      />
    </g>
  </svg>
);

export const FullLogo = ({}) => {
  return (
    <svg
      width="213"
      height="46"
      viewBox="0 0 213 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1301 15.4181V34.052H16.5837V22.8775L12.4094 34.052H8.74175L4.54107 22.8511V34.052H0V15.4181H5.36959L10.6073 28.3185L15.7895 15.4181H21.1327H21.1301Z"
        fill="#4C6FFF"
      />
      <path
        d="M38.3028 27.7369H28.0148C28.086 28.6565 28.3816 29.36 28.9066 29.8475C29.4291 30.3349 30.0729 30.5773 30.8328 30.5773C31.9674 30.5773 32.7564 30.1004 33.197 29.1439H38.0336C37.7856 30.1162 37.337 30.9936 36.6906 31.7709C36.0441 32.5509 35.234 33.1595 34.2578 33.6022C33.2841 34.0448 32.1944 34.2662 30.9885 34.2662C29.5346 34.2662 28.2417 33.9579 27.1071 33.3361C25.9725 32.7169 25.0859 31.8315 24.45 30.6827C23.8115 29.5339 23.4922 28.1875 23.4922 26.6487C23.4922 25.1099 23.8062 23.7635 24.4368 22.6147C25.0648 21.4659 25.9461 20.5806 27.0807 19.9614C28.2153 19.3422 29.5162 19.0312 30.9885 19.0312C32.4609 19.0312 33.6984 19.3316 34.8171 19.9324C35.9333 20.5331 36.8067 21.3921 37.4347 22.5067C38.0626 23.6212 38.3793 24.9229 38.3793 26.4089C38.3793 26.8332 38.3529 27.2758 38.3001 27.7369H38.3028ZM33.73 25.2153C33.73 24.438 33.4635 23.8188 32.9332 23.3577C32.4028 22.8966 31.7379 22.6674 30.9384 22.6674C30.1389 22.6674 29.5346 22.8887 29.0122 23.3314C28.4897 23.774 28.1652 24.4011 28.0412 25.2153H33.73Z"
        fill="#4C6FFF"
      />
      <path
        d="M40.7327 22.6119C41.3079 21.4631 42.0915 20.5777 43.0863 19.9585C44.0784 19.3393 45.1866 19.0284 46.4083 19.0284C47.382 19.0284 48.2738 19.2313 49.0786 19.6397C49.8834 20.0481 50.5193 20.5962 50.9784 21.2865V14.4121H55.5248V34.0526H50.9784V31.9288C50.5536 32.6376 49.9467 33.2041 49.1578 33.6283C48.3688 34.0526 47.4532 34.266 46.4057 34.266C45.184 34.266 44.0758 33.9524 43.0837 33.3227C42.0915 32.6956 41.3079 31.7997 40.73 30.643C40.1548 29.4837 39.8672 28.1451 39.8672 26.6222C39.8672 25.0992 40.1548 23.766 40.73 22.6145L40.7327 22.6119ZM50.0364 23.9662C49.4084 23.3128 48.6406 22.9834 47.7382 22.9834C46.8358 22.9834 46.0679 23.3075 45.44 23.953C44.812 24.5986 44.4953 25.4892 44.4953 26.6195C44.4953 27.7499 44.8093 28.651 45.44 29.3124C46.0679 29.9764 46.8358 30.3084 47.7382 30.3084C48.6406 30.3084 49.4084 29.9817 50.0364 29.3256C50.6644 28.6721 50.9811 27.7763 50.9811 26.6459C50.9811 25.5155 50.6671 24.6197 50.0364 23.9662Z"
        fill="#4C6FFF"
      />
      <path
        d="M59.1518 16.9989C58.6478 16.5299 58.3945 15.9502 58.3945 15.2599C58.3945 14.5696 58.6478 13.9635 59.1518 13.4945C59.6558 13.0255 60.3075 12.791 61.1044 12.791C61.9013 12.791 62.5266 13.0255 63.0306 13.4945C63.5346 13.9635 63.7879 14.5511 63.7879 15.2599C63.7879 15.9687 63.5346 16.5299 63.0306 16.9989C62.5266 17.4679 61.8828 17.7024 61.1044 17.7024C60.326 17.7024 59.6558 17.4679 59.1518 16.9989ZM63.3657 19.2412V34.0519H58.8193V19.2412H63.3657Z"
        fill="#4C6FFF"
      />
      <path
        d="M89.9856 20.7545C91.0753 21.8691 91.6215 23.4184 91.6215 25.3998V34.0528H87.1042V26.0111C87.1042 25.0546 86.8509 24.3169 86.3469 23.7952C85.8429 23.2735 85.1463 23.0126 84.2598 23.0126C83.3732 23.0126 82.6792 23.2735 82.1726 23.7952C81.6686 24.3169 81.4153 25.0546 81.4153 26.0111V34.0528H76.8954V26.0111C76.8954 25.0546 76.6421 24.3169 76.1381 23.7952C75.6341 23.2735 74.9375 23.0126 74.0509 23.0126C73.1644 23.0126 72.4704 23.2735 71.9638 23.7952C71.4598 24.3169 71.2065 25.0546 71.2065 26.0111V34.0528H66.6602V19.2421H71.2065V21.0997C71.6683 20.4805 72.2699 19.9904 73.014 19.6268C73.758 19.2632 74.5998 19.084 75.5391 19.084C76.6552 19.084 77.6526 19.3238 78.5287 19.8007C79.4047 20.2776 80.0934 20.96 80.5894 21.8453C81.104 21.0312 81.8032 20.3672 82.6898 19.8534C83.5764 19.3396 84.5421 19.084 85.587 19.084C87.4287 19.084 88.8958 19.6426 89.9856 20.7571V20.7545Z"
        fill="#4C6FFF"
      />
      <path
        d="M108.709 27.7369H98.421C98.4923 28.6565 98.7878 29.36 99.3129 29.8475C99.8353 30.3349 100.479 30.5773 101.239 30.5773C102.374 30.5773 103.163 30.1004 103.603 29.1439H108.44C108.192 30.1162 107.743 30.9936 107.097 31.7709C106.45 32.5509 105.64 33.1595 104.664 33.6022C103.69 34.0448 102.601 34.2662 101.395 34.2662C99.9409 34.2662 98.648 33.9579 97.5134 33.3361C96.3787 32.7169 95.4922 31.8315 94.8563 30.6827C94.2177 29.5339 93.8984 28.1875 93.8984 26.6487C93.8984 25.1099 94.2124 23.7635 94.8431 22.6147C95.4711 21.4659 96.3524 20.5806 97.487 19.9614C98.6216 19.3422 99.9224 19.0312 101.395 19.0312C102.867 19.0312 104.105 19.3316 105.223 19.9324C106.34 20.5331 107.213 21.3921 107.841 22.5067C108.469 23.6212 108.786 24.9229 108.786 26.4089C108.786 26.8332 108.759 27.2758 108.706 27.7369H108.709ZM104.136 25.2153C104.136 24.438 103.87 23.8188 103.339 23.3577C102.809 22.8966 102.144 22.6674 101.345 22.6674C100.545 22.6674 99.9409 22.8887 99.4184 23.3314C98.896 23.774 98.5714 24.4011 98.4474 25.2153H104.136Z"
        fill="#4C6FFF"
      />
      <path
        d="M119.45 30.2031V34.0526H117.139C115.49 34.0526 114.205 33.6495 113.284 32.8458C112.363 32.0396 111.901 30.7274 111.901 28.9041V23.0124H110.094V19.2446H111.901V15.6348H116.448V19.2446H119.424V23.0124H116.448V28.9568C116.448 29.3994 116.553 29.7182 116.767 29.9132C116.981 30.1082 117.334 30.2057 117.83 30.2057H119.45V30.2031Z"
        fill="#4C6FFF"
      />
      <path
        d="M128.514 19.7875C129.311 19.3185 130.197 19.084 131.171 19.084V23.8874H129.92C128.786 23.8874 127.936 24.1298 127.369 24.6172C126.801 25.1047 126.519 25.9584 126.519 27.1784V34.0528H121.973V19.2421H126.519V21.711C127.049 20.8968 127.714 20.2565 128.511 19.7875H128.514Z"
        fill="#4C6FFF"
      />
      <path
        d="M133.683 16.9989C133.179 16.5299 132.926 15.9502 132.926 15.2599C132.926 14.5696 133.179 13.9635 133.683 13.4945C134.187 13.0255 134.839 12.791 135.636 12.791C136.433 12.791 137.058 13.0255 137.562 13.4945C138.066 13.9635 138.319 14.5511 138.319 15.2599C138.319 15.9687 138.066 16.5299 137.562 16.9989C137.058 17.4679 136.414 17.7024 135.636 17.7024C134.857 17.7024 134.187 17.4679 133.683 16.9989ZM137.897 19.2412V34.0519H133.351V19.2412H137.897Z"
        fill="#4C6FFF"
      />
      <path
        d="M141.234 22.6108C141.862 21.462 142.74 20.5767 143.864 19.9575C144.988 19.3383 146.279 19.0273 147.733 19.0273C149.593 19.0273 151.147 19.5148 152.398 20.4871C153.646 21.4593 154.466 22.8321 154.857 24.6001H150.02C149.614 23.4671 148.825 22.9006 147.656 22.9006C146.822 22.9006 146.157 23.2247 145.661 23.8703C145.165 24.5158 144.917 25.4407 144.917 26.6448C144.917 27.8489 145.165 28.7738 145.661 29.4193C146.157 30.0649 146.822 30.389 147.656 30.389C148.825 30.389 149.614 29.8225 150.02 28.6895H154.857C154.466 30.4232 153.643 31.7855 152.385 32.7762C151.126 33.7669 149.577 34.2623 147.733 34.2623C146.279 34.2623 144.991 33.954 143.864 33.3321C142.74 32.7129 141.862 31.8276 141.234 30.6788C140.606 29.53 140.289 28.1836 140.289 26.6448C140.289 25.106 140.603 23.7596 141.234 22.6108Z"
        fill="#4C6FFF"
      />
      <path
        d="M159.85 33.6015C158.839 33.1589 158.043 32.5528 157.457 31.7835C156.871 31.0141 156.544 30.1498 156.473 29.196H160.964C161.016 29.7098 161.256 30.1261 161.681 30.4423C162.106 30.7611 162.629 30.9192 163.249 30.9192C163.816 30.9192 164.254 30.8085 164.565 30.5872C164.874 30.3659 165.03 30.0787 165.03 29.7256C165.03 29.3014 164.808 28.9878 164.365 28.7823C163.921 28.5794 163.204 28.3528 162.212 28.1052C161.148 27.8575 160.262 27.5966 159.555 27.3226C158.845 27.0486 158.235 26.6138 157.721 26.021C157.206 25.4281 156.95 24.6271 156.95 23.6179C156.95 22.7695 157.185 21.9948 157.655 21.2966C158.124 20.5984 158.816 20.045 159.729 19.6366C160.642 19.2309 161.726 19.0253 162.985 19.0253C164.845 19.0253 166.312 19.4864 167.383 20.406C168.455 21.3256 169.072 22.5482 169.23 24.0685H165.03C164.958 23.5547 164.734 23.1489 164.352 22.8486C163.969 22.5482 163.47 22.398 162.85 22.398C162.32 22.398 161.911 22.5007 161.629 22.7036C161.344 22.9065 161.204 23.1858 161.204 23.5389C161.204 23.9631 161.431 24.2819 161.882 24.4954C162.333 24.7088 163.038 24.9196 163.995 25.133C165.093 25.4176 165.99 25.6942 166.679 25.9683C167.37 26.2423 167.977 26.6849 168.499 27.2962C169.022 27.9075 169.291 28.7243 169.31 29.752C169.31 30.6188 169.067 31.3935 168.579 32.0733C168.091 32.7557 167.391 33.2906 166.478 33.6779C165.565 34.0679 164.507 34.2629 163.301 34.2629C162.008 34.2629 160.855 34.0416 159.845 33.5989L159.85 33.6015Z"
        fill="#4C6FFF"
      />
      <path
        d="M134.656 39.8628C134.901 39.7231 135.181 39.652 135.492 39.652C135.828 39.652 136.131 39.731 136.398 39.8891C136.664 40.0472 136.875 40.2712 137.031 40.5584C137.184 40.8456 137.263 41.1802 137.263 41.5623C137.263 41.9443 137.186 42.2737 137.031 42.5688C136.878 42.8639 136.664 43.0932 136.395 43.2565C136.126 43.4199 135.825 43.5016 135.492 43.5016C135.16 43.5016 134.888 43.4304 134.645 43.2908C134.403 43.1511 134.221 42.9719 134.097 42.7533V43.441H133.477V38.4083H134.097V40.4082C134.223 40.1869 134.411 40.0051 134.656 39.8654V39.8628ZM136.461 40.8298C136.347 40.6216 136.194 40.4635 136.002 40.3529C135.809 40.2448 135.595 40.1895 135.366 40.1895C135.136 40.1895 134.928 40.2448 134.735 40.3555C134.543 40.4662 134.387 40.6269 134.273 40.8377C134.16 41.0485 134.099 41.2935 134.099 41.5676C134.099 41.8416 134.157 42.0945 134.273 42.3053C134.39 42.5161 134.543 42.6768 134.735 42.7875C134.928 42.8982 135.139 42.9535 135.366 42.9535C135.593 42.9535 135.809 42.8982 136.002 42.7875C136.194 42.6768 136.347 42.5161 136.461 42.3053C136.574 42.0945 136.63 41.8469 136.63 41.5596C136.63 41.2724 136.574 41.0327 136.461 40.8245V40.8298Z"
        fill="#0B122F"
      />
      <path
        d="M141.431 39.7129L139.182 45.1935H138.541L139.277 43.3991L137.773 39.7129H138.462L139.634 42.7325L140.792 39.7129H141.433H141.431Z"
        fill="#0B122F"
      />
      <path
        d="M144.14 40.5607C144.293 40.2735 144.506 40.0495 144.776 39.8914C145.045 39.7333 145.348 39.6543 145.686 39.6543C146.024 39.6543 146.29 39.7254 146.533 39.8677C146.776 40.01 146.955 40.1865 147.069 40.3947V39.7149H147.694V45.2086H147.069V42.7529C146.95 42.9611 146.77 43.1376 146.525 43.2825C146.28 43.4275 145.995 43.5012 145.667 43.5012C145.34 43.5012 145.042 43.4195 144.776 43.2562C144.509 43.0928 144.295 42.8636 144.142 42.5685C143.989 42.2734 143.91 41.9387 143.91 41.562C143.91 41.1852 143.987 40.8479 144.142 40.5581L144.14 40.5607ZM146.897 40.8347C146.784 40.6266 146.631 40.4658 146.438 40.3552C146.245 40.2445 146.032 40.1892 145.802 40.1892C145.572 40.1892 145.359 40.2445 145.169 40.3525C144.979 40.4606 144.826 40.6213 144.712 40.8295C144.599 41.0376 144.543 41.2827 144.543 41.5646C144.543 41.8465 144.599 42.0995 144.712 42.3103C144.826 42.5211 144.979 42.6818 145.169 42.7924C145.359 42.9031 145.57 42.9584 145.802 42.9584C146.034 42.9584 146.245 42.9031 146.438 42.7924C146.631 42.6818 146.784 42.5211 146.897 42.3103C147.011 42.0995 147.066 41.8544 147.066 41.5725C147.066 41.2906 147.011 41.0455 146.897 40.8374V40.8347Z"
        fill="#0B122F"
      />
      <path
        d="M152.186 39.7129V43.4386H151.566V42.8879C151.447 43.0776 151.283 43.2278 151.072 43.3332C150.861 43.4386 150.629 43.494 150.373 43.494C150.083 43.494 149.821 43.4334 149.589 43.3148C149.357 43.1936 149.175 43.0144 149.04 42.7746C148.906 42.5349 148.84 42.2424 148.84 41.8972V39.7155H149.452V41.8155C149.452 42.1818 149.544 42.4637 149.732 42.6613C149.919 42.8589 150.172 42.9564 150.494 42.9564C150.816 42.9564 151.088 42.8537 151.278 42.6508C151.468 42.4479 151.563 42.1502 151.563 41.7602V39.7129H152.183H152.186Z"
        fill="#0B122F"
      />
      <path
        d="M153.373 40.5607C153.526 40.2735 153.74 40.0495 154.009 39.8914C154.278 39.7333 154.579 39.6543 154.911 39.6543C155.244 39.6543 155.521 39.7254 155.763 39.8651C156.004 40.0047 156.183 40.1813 156.302 40.3947V39.7149H156.927V43.4406H156.302V42.7477C156.18 42.9664 155.996 43.1455 155.753 43.2878C155.51 43.4301 155.228 43.5012 154.906 43.5012C154.584 43.5012 154.275 43.4195 154.006 43.2562C153.737 43.0928 153.526 42.8636 153.373 42.5685C153.22 42.2734 153.141 41.9387 153.141 41.562C153.141 41.1852 153.217 40.8479 153.373 40.5581V40.5607ZM156.133 40.8347C156.019 40.6266 155.866 40.4658 155.674 40.3552C155.481 40.2445 155.267 40.1892 155.038 40.1892C154.808 40.1892 154.594 40.2445 154.405 40.3525C154.215 40.4606 154.062 40.6213 153.948 40.8295C153.835 41.0376 153.779 41.2827 153.779 41.5646C153.779 41.8465 153.835 42.0995 153.948 42.3103C154.062 42.5211 154.215 42.6818 154.405 42.7924C154.594 42.9031 154.806 42.9584 155.038 42.9584C155.27 42.9584 155.481 42.9031 155.674 42.7924C155.866 42.6818 156.019 42.5211 156.133 42.3103C156.246 42.0995 156.302 41.8544 156.302 41.5725C156.302 41.2906 156.246 41.0455 156.133 40.8374V40.8347Z"
        fill="#0B122F"
      />
      <path
        d="M161.033 40.0582C161.315 40.3322 161.455 40.7275 161.455 41.2439V43.4388H160.843V41.3309C160.843 40.9593 160.751 40.6748 160.563 40.4772C160.376 40.2795 160.123 40.182 159.801 40.182C159.479 40.182 159.215 40.2848 159.02 40.4877C158.827 40.6906 158.729 40.9883 158.729 41.3783V43.4388H158.109V39.713H158.729V40.2426C158.851 40.0529 159.02 39.9054 159.231 39.8C159.442 39.6946 159.674 39.6445 159.927 39.6445C160.381 39.6445 160.748 39.7815 161.03 40.0556L161.033 40.0582Z"
        fill="#0B122F"
      />
      <path
        d="M163.361 40.2239V42.4214C163.361 42.6032 163.4 42.7297 163.477 42.8061C163.553 42.8799 163.688 42.9194 163.878 42.9194H164.334V43.4437H163.775C163.429 43.4437 163.171 43.3647 162.999 43.2066C162.828 43.0485 162.741 42.7876 162.741 42.424V40.2265H162.258V39.7154H162.741V38.7773H163.361V39.7154H164.334V40.2265H163.361V40.2239Z"
        fill="#0B122F"
      />
      <path
        d="M168.529 39.7129V43.4386H167.909V42.8879C167.791 43.0776 167.627 43.2278 167.416 43.3332C167.205 43.4386 166.973 43.494 166.717 43.494C166.426 43.494 166.165 43.4334 165.933 43.3148C165.701 43.1936 165.519 43.0144 165.384 42.7746C165.25 42.5349 165.184 42.2424 165.184 41.8972V39.7155H165.796V41.8155C165.796 42.1818 165.888 42.4637 166.075 42.6613C166.263 42.8589 166.516 42.9564 166.838 42.9564C167.16 42.9564 167.432 42.8537 167.622 42.6508C167.812 42.4479 167.907 42.1502 167.907 41.7602V39.7129H168.527H168.529Z"
        fill="#0B122F"
      />
      <path
        d="M174.975 39.8251C175.202 39.9463 175.381 40.1254 175.513 40.3652C175.645 40.605 175.711 40.8975 175.711 41.2426V43.4375H175.099V41.3296C175.099 40.9581 175.007 40.6735 174.822 40.4759C174.637 40.2783 174.389 40.1808 174.075 40.1808C173.761 40.1808 173.497 40.2835 173.305 40.4891C173.112 40.6946 173.02 40.9923 173.02 41.3823V43.4349H172.408V41.327C172.408 40.9554 172.315 40.6709 172.133 40.4733C171.948 40.2756 171.7 40.1781 171.386 40.1781C171.072 40.1781 170.809 40.2809 170.616 40.4864C170.423 40.6919 170.331 40.9897 170.331 41.3797V43.4322H169.711V39.7065H170.331V40.244C170.452 40.049 170.619 39.8988 170.824 39.7961C171.03 39.6907 171.26 39.6406 171.508 39.6406C171.822 39.6406 172.099 39.7118 172.339 39.8514C172.579 39.9911 172.759 40.1992 172.877 40.4706C172.983 40.2071 173.154 40.0042 173.394 39.8593C173.635 39.7144 173.904 39.6406 174.199 39.6406C174.495 39.6406 174.748 39.7012 174.975 39.8198V39.8251Z"
        fill="#0B122F"
      />
      <path
        d="M179.989 39.8658C180.232 39.7235 180.514 39.6523 180.836 39.6523C181.158 39.6523 181.469 39.7314 181.739 39.8895C182.008 40.0476 182.221 40.2715 182.375 40.5587C182.528 40.8459 182.607 41.1806 182.607 41.5626C182.607 41.9447 182.53 42.2741 182.375 42.5692C182.221 42.8643 182.008 43.0935 181.739 43.2569C181.469 43.4202 181.169 43.5019 180.836 43.5019C180.504 43.5019 180.237 43.4308 179.995 43.2885C179.752 43.1462 179.567 42.967 179.44 42.7536V45.2093H178.82V39.7156H179.44V40.4033C179.562 40.1899 179.746 40.0133 179.989 39.8684V39.8658ZM181.802 40.8301C181.688 40.622 181.535 40.4639 181.343 40.3532C181.15 40.2452 180.936 40.1899 180.707 40.1899C180.477 40.1899 180.269 40.2452 180.076 40.3559C179.884 40.4665 179.728 40.6273 179.615 40.838C179.501 41.0488 179.44 41.2939 179.44 41.5679C179.44 41.8419 179.498 42.0949 179.615 42.3057C179.731 42.5165 179.884 42.6772 180.076 42.7879C180.269 42.8985 180.48 42.9539 180.707 42.9539C180.934 42.9539 181.15 42.8985 181.343 42.7879C181.535 42.6772 181.688 42.5165 181.802 42.3057C181.915 42.0949 181.971 41.8472 181.971 41.56C181.971 41.2728 181.915 41.033 181.802 40.8249V40.8301Z"
        fill="#0B122F"
      />
      <path
        d="M183.556 40.5607C183.709 40.2735 183.923 40.0495 184.192 39.8914C184.461 39.7333 184.762 39.6543 185.095 39.6543C185.427 39.6543 185.704 39.7254 185.947 39.8651C186.187 40.0047 186.367 40.1813 186.485 40.3947V39.7149H187.111V43.4406H186.485V42.7477C186.364 42.9664 186.179 43.1455 185.936 43.2878C185.694 43.4301 185.411 43.5012 185.089 43.5012C184.768 43.5012 184.459 43.4195 184.19 43.2562C183.921 43.0928 183.709 42.8636 183.556 42.5685C183.403 42.2734 183.324 41.9387 183.324 41.562C183.324 41.1852 183.401 40.8479 183.556 40.5581V40.5607ZM186.314 40.8347C186.2 40.6266 186.047 40.4658 185.855 40.3552C185.662 40.2445 185.448 40.1892 185.219 40.1892C184.989 40.1892 184.775 40.2445 184.585 40.3525C184.395 40.4606 184.242 40.6213 184.129 40.8295C184.016 41.0376 183.96 41.2827 183.96 41.5646C183.96 41.8465 184.016 42.0995 184.129 42.3103C184.242 42.5211 184.395 42.6818 184.585 42.7924C184.775 42.9031 184.987 42.9584 185.219 42.9584C185.451 42.9584 185.662 42.9031 185.855 42.7924C186.047 42.6818 186.2 42.5211 186.314 42.3103C186.427 42.0995 186.483 41.8544 186.483 41.5725C186.483 41.2906 186.427 41.0455 186.314 40.8374V40.8347Z"
        fill="#0B122F"
      />
      <path
        d="M189.38 39.823C189.583 39.7045 189.829 39.6465 190.119 39.6465V40.2868H189.955C189.261 40.2868 188.913 40.6635 188.913 41.4145V43.4407H188.293V39.715H188.913V40.321C189.021 40.1076 189.177 39.9416 189.38 39.8257V39.823Z"
        fill="#0B122F"
      />
      <path
        d="M190.974 40.5607C191.127 40.2735 191.341 40.0495 191.61 39.8914C191.879 39.7333 192.18 39.6543 192.513 39.6543C192.845 39.6543 193.122 39.7254 193.365 39.8651C193.605 40.0047 193.785 40.1813 193.903 40.3947V39.7149H194.529V43.4406H193.903V42.7477C193.782 42.9664 193.597 43.1455 193.354 43.2878C193.112 43.4301 192.829 43.5012 192.507 43.5012C192.186 43.5012 191.877 43.4195 191.608 43.2562C191.339 43.0928 191.127 42.8636 190.974 42.5685C190.821 42.2734 190.742 41.9387 190.742 41.562C190.742 41.1852 190.819 40.8479 190.974 40.5581V40.5607ZM193.732 40.8347C193.618 40.6266 193.465 40.4658 193.273 40.3552C193.08 40.2445 192.866 40.1892 192.637 40.1892C192.407 40.1892 192.193 40.2445 192.003 40.3525C191.813 40.4606 191.66 40.6213 191.547 40.8295C191.434 41.0376 191.378 41.2827 191.378 41.5646C191.378 41.8465 191.434 42.0995 191.547 42.3103C191.66 42.5211 191.813 42.6818 192.003 42.7924C192.193 42.9031 192.405 42.9584 192.637 42.9584C192.869 42.9584 193.08 42.9031 193.273 42.7924C193.465 42.6818 193.618 42.5211 193.732 42.3103C193.845 42.0995 193.901 41.8544 193.901 41.5725C193.901 41.2906 193.845 41.0455 193.732 40.8374V40.8347Z"
        fill="#0B122F"
      />
      <path
        d="M195.71 40.5622C195.863 40.275 196.077 40.051 196.346 39.8929C196.615 39.7348 196.919 39.6558 197.256 39.6558C197.547 39.6558 197.816 39.7216 198.066 39.856C198.317 39.9904 198.507 40.1643 198.639 40.383V38.4121H199.264V43.4447H198.639V42.7439C198.518 42.9652 198.335 43.1496 198.095 43.2919C197.855 43.4342 197.573 43.5053 197.251 43.5053C196.929 43.5053 196.618 43.4237 196.349 43.2603C196.079 43.0969 195.866 42.8677 195.713 42.5726C195.56 42.2775 195.48 41.9429 195.48 41.5661C195.48 41.1893 195.557 40.852 195.713 40.5622H195.71ZM198.467 40.8362C198.354 40.628 198.201 40.4673 198.008 40.3567C197.816 40.246 197.602 40.1907 197.372 40.1907C197.143 40.1907 196.929 40.246 196.739 40.354C196.549 40.4621 196.396 40.6228 196.283 40.8309C196.169 41.0391 196.114 41.2841 196.114 41.5661C196.114 41.848 196.169 42.101 196.283 42.3117C196.396 42.5225 196.549 42.6833 196.739 42.7939C196.929 42.9046 197.14 42.9599 197.372 42.9599C197.605 42.9599 197.816 42.9046 198.008 42.7939C198.201 42.6833 198.354 42.5225 198.467 42.3117C198.581 42.101 198.636 41.8559 198.636 41.574C198.636 41.292 198.581 41.047 198.467 40.8388V40.8362Z"
        fill="#0B122F"
      />
      <path
        d="M200.473 38.9856C200.391 38.904 200.352 38.8038 200.352 38.6853C200.352 38.5667 200.394 38.4666 200.473 38.3849C200.552 38.3032 200.655 38.2637 200.774 38.2637C200.892 38.2637 200.982 38.3032 201.064 38.3849C201.143 38.4666 201.183 38.5667 201.183 38.6853C201.183 38.8038 201.143 38.904 201.064 38.9856C200.985 39.0673 200.887 39.1068 200.774 39.1068C200.66 39.1068 200.555 39.0673 200.473 38.9856ZM201.072 39.7129V43.4386H200.452V39.7129H201.072Z"
        fill="#0B122F"
      />
      <path
        d="M204.646 39.8638C204.889 40.0035 205.068 40.18 205.187 40.3934V39.7136H205.812V43.521C205.812 43.8609 205.739 44.164 205.593 44.4301C205.448 44.6962 205.24 44.9017 204.971 45.0519C204.702 45.2021 204.388 45.2759 204.029 45.2759C203.538 45.2759 203.129 45.1599 202.804 44.9281C202.477 44.6962 202.285 44.3826 202.227 43.9821H202.839C202.907 44.2087 203.047 44.3906 203.261 44.5302C203.475 44.6699 203.731 44.7384 204.031 44.7384C204.372 44.7384 204.649 44.633 204.865 44.4195C205.081 44.2061 205.19 43.9084 205.19 43.521V42.7385C205.068 42.9572 204.886 43.1363 204.646 43.2813C204.406 43.4262 204.124 43.5 203.802 43.5C203.48 43.5 203.168 43.4183 202.899 43.2549C202.63 43.0916 202.416 42.8623 202.263 42.5672C202.11 42.2721 202.031 41.9375 202.031 41.5607C202.031 41.1839 202.108 40.8466 202.263 40.5568C202.416 40.2696 202.63 40.0456 202.899 39.8875C203.168 39.7294 203.469 39.6504 203.802 39.6504C204.134 39.6504 204.406 39.7215 204.649 39.8612L204.646 39.8638ZM205.018 40.8361C204.905 40.6279 204.752 40.4672 204.559 40.3565C204.366 40.2459 204.153 40.1905 203.923 40.1905C203.694 40.1905 203.48 40.2459 203.29 40.3539C203.1 40.4619 202.947 40.6227 202.833 40.8308C202.72 41.039 202.665 41.284 202.665 41.566C202.665 41.8479 202.72 42.1008 202.833 42.3116C202.947 42.5224 203.1 42.6831 203.29 42.7938C203.48 42.9045 203.691 42.9598 203.923 42.9598C204.155 42.9598 204.366 42.9045 204.559 42.7938C204.752 42.6831 204.905 42.5224 205.018 42.3116C205.132 42.1008 205.187 41.8558 205.187 41.5739C205.187 41.2919 205.132 41.0469 205.018 40.8387V40.8361Z"
        fill="#0B122F"
      />
      <path
        d="M212.264 39.8251C212.491 39.9463 212.67 40.1254 212.802 40.3652C212.934 40.605 213 40.8975 213 41.2426V43.4375H212.388V41.3296C212.388 40.9581 212.296 40.6735 212.111 40.4759C211.926 40.2783 211.678 40.1808 211.364 40.1808C211.05 40.1808 210.786 40.2835 210.594 40.4891C210.401 40.6946 210.309 40.9923 210.309 41.3823V43.4349H209.697V41.327C209.697 40.9554 209.604 40.6709 209.422 40.4733C209.238 40.2756 208.99 40.1781 208.676 40.1781C208.362 40.1781 208.098 40.2809 207.905 40.4864C207.712 40.6919 207.62 40.9897 207.62 41.3797V43.4322H207V39.7065H207.62V40.244C207.741 40.049 207.908 39.8988 208.113 39.7961C208.319 39.6907 208.549 39.6406 208.797 39.6406C209.111 39.6406 209.388 39.7118 209.628 39.8514C209.868 39.9911 210.048 40.1992 210.166 40.4706C210.272 40.2071 210.443 40.0042 210.684 39.8593C210.924 39.7144 211.193 39.6406 211.488 39.6406C211.784 39.6406 212.037 39.7012 212.264 39.8198V39.8251Z"
        fill="#0B122F"
      />
      <path
        d="M197.858 0.495046L184.169 13.543C183.765 13.9277 183.628 14.5126 183.82 15.037L190.995 34.8224C191.145 35.236 191.538 35.5127 191.979 35.5127H210.953C212.275 35.5285 213.201 34.2137 212.737 32.9779L200.776 1.16167C200.325 -0.0398362 198.789 -0.390276 197.858 0.495046Z"
        fill="#38DBFF"
      />
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M169.621 13.5773L176.851 34.0978C177.149 34.9463 177.951 35.5128 178.851 35.5128H192.276C193.559 35.5128 194.656 34.5879 194.87 33.3232L197.527 18.8682C197.762 17.585 196.949 16.3413 195.678 16.0383L172.381 10.5182C170.574 10.0861 169.004 11.8225 169.621 13.5747V13.5773Z"
          fill="#4C6FFF"
        />
      </g>
    </svg>
  );
};
