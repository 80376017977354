import {
  fetchMedicineList,
  addMedicineToAPI,
  updateMedicineInAPI,
  deleteMedicineFromAPI,
  getMedicineByIdFromAPI,
  linkDoctorTargetFromAPI,
  modifyTargetAPI,
  closeTargetAPI,
  closeCreateMedicineTargetAPI,
  getAllMedicineUnitsAndTypeApi,
} from "./medicineApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchMedicines = createAsyncThunk(
  "medicine/fetchMedicines",
  fetchMedicineList
);
export const addMedicine = createAsyncThunk(
  "medicine/addMedicine",
  addMedicineToAPI
);
export const updateMedicine = createAsyncThunk(
  "medicine/updateMedicine",
  updateMedicineInAPI
);
export const deleteMedicine = createAsyncThunk(
  "medicine/deleteMedicine",
  deleteMedicineFromAPI
);
export const getMedicineById = createAsyncThunk(
  "medicine/get-medicine-byId",
  getMedicineByIdFromAPI
);
export const linkDoctor = createAsyncThunk(
  "medicine/link-doctor-target",
  linkDoctorTargetFromAPI
);
export const modifyTarget = createAsyncThunk(
  "medicine/update-medicine-target",
  modifyTargetAPI
);
export const closeTarget = createAsyncThunk(
  "medicine/close-target",
  closeTargetAPI
);
export const closeAndCreateMedicineTarget = createAsyncThunk(
  "medicine/close-create-medicine-target",
  closeCreateMedicineTargetAPI
);

export const getAllMedicineUnitsAndTypes = createAsyncThunk(
  "medicine/medicine-unit-and-type",
  getAllMedicineUnitsAndTypeApi
);
