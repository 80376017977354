import CommonCard from "../CommonCard";
import classes from "./FilterActionButton.module.css";
import React, { forwardRef } from "react";
import Button from "../Button";
const FilterActionButton = forwardRef(
  ({ children, label, className, onClick }, ref) => {

    return (
      <CommonCard className={[classes.wrapper, className].join(" ")}>
        <span
          className={[
            "button_transparent p-0 h-100 w-100",
            classes["action"],
          ].join(" ")}
          onClick={onClick}
          ref={ref}
        >
          {label || children}
        </span>
      </CommonCard>
    );
  }
);

export default FilterActionButton;
