import { toast } from "react-toastify";
import { Post } from "../../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../../config";
import { getToken } from "../../helpers/tokenStorage";
//get all location type active and inactive
export const fetchLocationTypeListApi = async (
  pageNo = 1,
  limit = 10,
  search = "",
  sortField = "",
  sortDirection = "desc"
) => {
  const response = await Post(
    BaseURL("location/get-location-list"),
    {
      pageNumber: pageNo,
      pageSize: 10,
      totalRowsCount: 0,
      sortField,
      sortDirection,
      search,
      filter: {
        locationId: 0,
        locationTypeId: 0,
        fromDate: "",
        toDate: "",
        targetStatus: "",
        targetFilter: {
          minValue: 0,
          maxValue: 0,
        },
      },
    },
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.error);
    return [];
  }
};

//get all active location
export const getAllActiveLocationTypeApi = async () => {
  const response = await Post(
    BaseURL("locationtype/get-location-types"),
    {},
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    return response?.data?.body;
  }
  toast.error(response?.headers?.error);
  return [];
};

export const addLocationTypeIntoApi = async (params) => {
  const response = await Post(
    BaseURL("locationtype/add-location-type"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Location Created Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};

export const updateLocationTypeApi = async (params) => {
  const response = await Post(
    BaseURL("locationtype/update-location-type"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Location Updated Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
