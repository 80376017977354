import React from "react";
import { Form } from "react-bootstrap";
import Classes from "./FormCheckbox.module.css";
const FormCheckbox = ({ id, setter, className, label, value, ...rest }) => {
  return (
    <Form.Check
      id={id}
      checked={value}
      value={value}
      label={label}
      onChange={(e) => {
        setter(e.target.checked);
      }}
      {...rest}
      type={`checkbox`}
      className={[className, Classes.checkbox].join(" ")}
    />
  );
};

export default FormCheckbox;
