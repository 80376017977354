import React, { useEffect, useRef, useState } from "react";
import { FaBars, FaListUl } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { logo, shortLogo } from "../../../assets/constant";
import {
  ToggleIcon,
  BellIcon,
  CapsuleIcon,
  GroupUser,
  HomeIcon,
  ListIcon,
  LocationIcon,
  UserIcon,
  RoleIcon,
  UserList,
  ShortLogo,
  FullLogo,
} from "../../../assets/Icon";
import BrandLogo from "./BrandLogo";
import MenuItem from "../../../components/MenuItem";
import ToggleMode from "../../../components/ToggleMode";
import classes from "./SidebarSkeleton.module.css";
const SidebarSkeleton = ({ children }) => {
  const sidebarAside = useRef(null);
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSidebarClose, setIsSidebarClose] = useState(true);
  const [active, setActive] = useState(null);
  const [isClickOnToggle, setIsClickOnToggle] = useState(false);
  const [markAsActive, setMarkAsActive] = useState(0);
  const detectListHandler = (idx) => {
    if (active === idx) {
      setActive(null);
    } else {
      setActive(idx);
    }
  };
  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setIsSidebarClose(true);
    }
  };

  function toggleTheme() {
    var theme = document.getElementsByTagName("link")[0];
    if (theme.getAttribute("href") == "index.css") {
      theme.setAttribute("href", "dark.css");
    } else {
      theme.setAttribute("href", "index.css");
    }
  }
  useEffect(() => {
    if (window.innerWidth < 440) {
      setIsSidebarClose(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <div className={classes["sidebar__wrapper"]}>
        <aside
          className={[
            classes["sidebar__sideContent"],
            isSidebarClose && classes["width-0"],
          ].join(" ")}
          ref={sidebarAside}
        >
          <div className={[classes["sidebar__mainMenu"]].join(" ")}>
            {/* <span
              className={[
                classes["sidebar__openerToggle"],
                isSidebarClose && classes["show__openerToggle"],
              ].join(" ")}
              onClick={() => {
                isSidebarClose
                  ? setIsClickOnToggle(true)
                  : setIsClickOnToggle(false);
                setIsSidebarClose((prev) => !prev);
              }}
            >
              {isSidebarClose && (
                <FaBars
                  color={`#fff`}
                  className={classes["toogle"]}
                  size={17}
                />
              )}
              {!isSidebarClose && (
                <RxCross1
                  color={`#fff`}
                  className={classes["toggle"]}
                  size={17}
                  style={{ strokeWidth: "1" }}
                />
              )}
            </span> */}
            {/* <div
              className={[
                classes.logoWrapper,

                isSidebarClose
                  ? classes["forshortLogo"]
                  : classes["forLongLogo"],

                classes["overflow-x-hidden"],
                `${isSidebarClose && "px-0"}`,
              ].join(" ")}
            >
              {!isSidebarClose && (
                <span
                  className={classes["brand-logo"]}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <FullLogo />
                </span>
              )}
              {isSidebarClose && (
                <span
                  onClick={() => {
                    navigate("/");
                  }}
                  className={`${classes["ml-5px"]} ${classes["apply-transition"]} ${classes["brand-logo"]}`}
                >
           

                  <ShortLogo />
                </span>
              )}
            </div> */}
            <BrandLogo isSidebarClose={isSidebarClose} />
            <ul className={classes["sidebarList"]}>
              <MenuItem
                ref={sidebarAside}
                path={"/"}
                text={"Dashboard"}
                icon={<HomeIcon />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(1)}
                markAsActive={markAsActive === 1}
                setMarkAsActive={() => setMarkAsActive(1)}
                isActive={active === 1}
              />
              <MenuItem
                ref={sidebarAside}
                path={"/medicine"}
                text={"Medicine List"}
                icon={<CapsuleIcon />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(2)}
                markAsActive={markAsActive === 2}
                setMarkAsActive={() => setMarkAsActive(2)}
                isActive={active === 2}
              />

              <MenuItem
                ref={sidebarAside}
                path={"/doctor-list"}
                text={"Doctor List"}
                icon={<UserIcon />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(3)}
                markAsActive={markAsActive === 3}
                setMarkAsActive={() => setMarkAsActive(3)}
                isActive={active === 3}
              />
              <MenuItem
                path={"/location"}
                ref={sidebarAside}
                text={"Location List"}
                icon={<LocationIcon />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(4)}
                markAsActive={markAsActive === 4}
                setMarkAsActive={() => setMarkAsActive(4)}
                isActive={active === 4}
              />

              <MenuItem
                ref={sidebarAside}
                path={"/reports"}
                text={"Reports"}
                icon={<GroupUser />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(5)}
                markAsActive={markAsActive === 5}
                setMarkAsActive={() => setMarkAsActive(5)}
                isActive={active === 5}
              />

              <MenuItem
                path={"/role-management"}
                ref={sidebarAside}
                text={"Role Management"}
                leftSideLink={true}
                listOfOptions={[
                  {
                    id: "1",
                    name: "User List",
                    icon: <UserList />,
                    router: "/user-list",
                  },
                ]}
                icon={<RoleIcon />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(6)}
                markAsActive={markAsActive === 6}
                setMarkAsActive={() => setMarkAsActive(6)}
                isActive={active === 6}
              />

              <div className={classes["down-actions-wrapper"]}>
                <div
                  className={[
                    classes["seprator-line"],

                    !isSidebarClose && classes["seprator-padding"],
                  ].join(" ")}
                ></div>
                <div className={classes["down-actions"]}>
                  <MenuItem
                    className={classes["mt-18"]}
                    ref={sidebarAside}
                    path={"/privacy-policy"}
                    text={"Privacy Policy"}
                    icon={<ListIcon />}
                    isClickOnToggle={isClickOnToggle}
                    isSidebarClose={isSidebarClose}
                    setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                    activeHandler={() => detectListHandler(7)}
                    markAsActive={markAsActive === 7}
                    setMarkAsActive={() => setMarkAsActive(7)}
                    isActive={active === 7}
                  />

                  <MenuItem
                    className={classes["mt-18"]}
                    ref={sidebarAside}
                    path={"/notifications"}
                    text={"Notifications"}
                    icon={
                      <BellIcon
                        className={classes["noti-icon"]}
                        size={20}
                        color="var(--blue-gray)"
                      />
                    }
                    isClickOnToggle={isClickOnToggle}
                    isSidebarClose={isSidebarClose}
                    setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                    activeHandler={() => detectListHandler(8)}
                    markAsActive={markAsActive === 8}
                    setMarkAsActive={() => setMarkAsActive(8)}
                    isActive={active === 8}
                  />
                  <div
                    className={[
                      classes["toggle-mode-wrapper"],
                      !isSidebarClose && classes["full-toggle-mode"],
                    ].join(" ")}
                  >
                    {!isSidebarClose && (
                      <label htmlFor="mode" className={classes.themeIndicator}>
                        Light
                      </label>
                    )}
                    <ToggleMode
                      id="mode"
                      value={isDarkMode}
                      setter={(e) => {
                        setIsDarkMode(e);
                        toggleTheme();
                      }}
                    />
                    {!isSidebarClose && (
                      <label htmlFor="mode" className={classes.themeIndicator}>
                        Dark
                      </label>
                    )}
                  </div>
                  <div className={classes.collapseWrapper}>
                    {!isSidebarClose && (
                      <span
                        className={classes.sideBarToggle}
                        onClick={() => {
                          isSidebarClose
                            ? setIsClickOnToggle(true)
                            : setIsClickOnToggle(false);
                          setIsSidebarClose((prev) => !prev);
                        }}
                      >
                        <span>
                          <ToggleIcon />
                        </span>
                        <span className={classes.toggleText}>Collapse</span>
                      </span>
                    )}
                    {isSidebarClose && (
                      <span
                        onClick={() => {
                          isSidebarClose
                            ? setIsClickOnToggle(true)
                            : setIsClickOnToggle(false);
                          setIsSidebarClose((prev) => !prev);
                        }}
                        className={classes.sidebarOffCollapse}
                      >
                        <ToggleIcon />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </aside>
        <div
          className={[
            classes["sidebar__mainContent"],
            isSidebarClose && classes["margin-left-0"],
          ].join(" ")}
          onMouseEnter={() => {
            if (!isClickOnToggle) {
              if (sidebarAside?.current?.offsetWidth == 250)
                setIsSidebarClose(true);
              setActive(null);
            }
          }}
        >
          <div className={classes["content-wrapper"]}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default SidebarSkeleton;
