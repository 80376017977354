import React from "react";
import { FullLogo, ShortLogo } from "../../../../assets/Icon";
import clsx from "clsx";
import classes from "./BrandLogo.module.css";
import { useNavigate } from "react-router-dom";
const BrandLogo = ({ isSidebarClose }) => {
  const navigate = useNavigate();
  let logo = <FullLogo />;
  /* when side bar is close display short logo */
  if (isSidebarClose) {
    logo = (
      <span className={classes.shortLogoWrapper}>
        <ShortLogo />
      </span>
    );
  }

  return (
    <div
      className={clsx(classes.logoWrapper, {
        [classes["forShortLogo"]]: isSidebarClose,
        [classes["forLongLogo"]]: !isSidebarClose,
      })}
    >
      <span
        onClick={() => {
          navigate("/");
        }}
      >
        {logo}
      </span>
    </div>
  );
};

export default BrandLogo;
