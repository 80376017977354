import { createSlice } from "@reduxjs/toolkit";
import { createAsyncCases } from "../../../helpers/reduxUtils";
import {
  getAllProvinces,
  getAllRelatedCities,
} from "../../../api/addreess/addressThunk";

const initialState = {
  status: "idle",
  token: "",
  isLogin: false,
  loading: null,
  error: null,
  allProvinceItems: [],
  allCityItems: [],
};
const addressSlice = createSlice({
  name: "address",
  initialState,
  extraReducers: (builder) => {
    createAsyncCases(builder, getAllProvinces, {
      fulfilled(state, action) {
        state.allProvinceItems = action.payload;
      },
    });
    createAsyncCases(builder, getAllRelatedCities, {
      fulfilled(state, action) {
        state.allCityItems = action.payload;
      },
    });
  },
});

export default addressSlice?.reducer;

export const { logout } = addressSlice.actions;
