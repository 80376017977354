import { combineReducers } from "redux";
import authSlice from "./features/auth/authSlice";
import locationTypeSlice from "./features/locationType/locationTypeSlice";
import locationSlice from "./features/location/locationSlice";
import medicineSlice from "./features/medicine/medicineSlice";
import doctorSlice from "./features/doctor/doctorSlice";
import userSlice from "./features/user/userSlice";
import addressSlice from "./features/address/addressSlice";
import roleSlice from "./features/role/roleSlice";
// import doctorSlice from "./features/doctor/doctorSlice";
const rootReducer = combineReducers({
  locationTypeReducer: locationTypeSlice,
  locationReducer: locationSlice,
  authReducer: authSlice,
  medicineReducer: medicineSlice,
  doctorReducer: doctorSlice,
  userReducer: userSlice,
  addressReducer: addressSlice,
  roleReducer: roleSlice,
});
export default rootReducer;
