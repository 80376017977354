import { toast } from "react-toastify";
import { Post } from "../../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../../config";
import { getToken } from "../../helpers/tokenStorage";
// src/api/medicineAPI.js
//get all medicine handler
export const fetchLocationList = async (
  pageNo = 1,
  limit = 10,
  search = "",
  sortField = "",
  sortDirection = "desc"
) => {
  const response = await Post(
    BaseURL("location/get-location-list"),
    {
      pageNumber: pageNo,
      pageSize: 10,
      totalRowsCount: 0,
      sortField,
      sortDirection,
      search,
      filter: {
        locationId: 0,
        locationTypeId: 0,
        fromDate: "",
        toDate: "",
        targetStatus: "",
        targetFilter: {
          minValue: 0,
          maxValue: 0,
        },
      },
    },
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error === 200) {
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.error);
    return [];
  }
};
//add medicine handler
export const addLocationToAPI = async (params) => {
  const response = await Post(
    BaseURL("location/add-location"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Location Created Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
};
//update medicine
export const updateLocationInAPI = async (params) => {
  const response = await Post(
    BaseURL("location/update-location"),
    params,
    apiHeader(getToken())
  );
  if (response?.data?.headers?.error == 200) {
    toast.success("Location Updated Successfully");
  } else {
    toast.error(response?.data?.headers?.message);
  }
  // return response.data;
};
//delete medicine

//get Medicine By Id
export const getLocationByIdFromAPI = async (params) => {
  const response = await Post(
    BaseURL("location/get-location-ById"),
    params,
    apiHeader(getToken())
  );

  if (response?.data?.headers?.error === 200) return response?.data?.body;
  toast.error(response?.data?.headers?.message);
  return null;
};

export const getAllLocations = async (params) => {
  const response = await Post(
    BaseURL("location/locations"),
    params,
    apiHeader(getToken())
  );

  if (response?.data?.headers?.error === 200) return response?.data?.body;
  toast.error(response?.data?.headers?.message);
  return null;
};
