import React from "react";
import ModalAction from "../ModalAction";
import ModalSkeleton from "../ModalSkeleton";
import Classes from "./AreYouSureModal.module.css";

const AreYouSureModal = ({
  show,
  setShow,
  label,
  title = "",
  subTitle = "delete",
  icon,
  onClick,
  width = "370px",
  content,
}) => {
  const onCancel = () => {
    setShow(false);
  };

  const onConfirm = () => {
    onClick && onClick();
    setShow(false);
  };
  let value = "";
  if (!content)
    value = (
      <>
        {" "}
        {`Are you sure you want to ${subTitle}`}{" "}
        <span className="fw-700">{label}</span>
      </>
    );
  else {
    value = content;
  }
  return (
    <ModalSkeleton setShow={setShow} show={show} width={width} bodyPadding="">
      <div className={Classes.wrapper}>
        <span className={Classes.circle}>
          {/* <RiDeleteBin6Line  /> */}
          {icon}
        </span>
        <h2 className={[Classes.heading, "fw-600", "lh-38"].join(" ")}>
          {title}
        </h2>

        <p
          className={["Inter-bold f14 lh-23 pt-24", Classes.description].join(
            " "
          )}
        >
          {value}
        </p>

        <ModalAction controls={{ onCancel, onConfirm }} />
      </div>
    </ModalSkeleton>
  );
};

export default AreYouSureModal;
