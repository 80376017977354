import React, { useState } from "react";
import "./MultipleFileUpload.css"; // Import CSS file for styling
import { cloud } from "../../assets/constant";
import classes from "./MultiplFileUpload.module.css";
import { crossIcon, csv } from "../../assets/constant";
import { toast } from "react-toastify";
function MultipleFileUpload() {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});

  
  const handleFileChange = (event) => {
    // const selectedFile = event.target.files;
    // if (selectedFile) {
    //   selectedFile?.forEach((file) => {
    //     if (file.size > 5 * 1024 * 1024) {
    //       toast.warning("File size must be less than 5MB");
    //       return;
    //     }
    //   });
    // }
    const uploadedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

    // Simulate file upload process for each newly added file
    uploadedFiles.forEach((file) => {
      uploadFile(file);
    });
  };

  const removeFileHandler = (filename) => {
  
    setFiles((currentState) => {
      return currentState?.filter((file) => file?.name != filename);
    });
    setUploadProgress((currentState) => {
      const newObj = { ...currentState };
      delete newObj[filename];
     
      return newObj;
    });
  };

  const uploadFile = (file) => {
    const totalSize = file.size;
    let uploadedBytes = 0;
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [file.name]: 0, // Initialize progress for each new file
    }));

    const progressInterval = setInterval(() => {
      uploadedBytes += Math.random() * 1000; // Simulate progress
      const progress = Math.min((uploadedBytes / totalSize) * 100, 100);
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: progress, // Update progress for the current file
      }));
      if (progress === 100) {
        clearInterval(progressInterval);
      }
    }, 100);
  };


  return (
    <div className="container">
      <div
        className="upload-area mt-16 w-100"
        onClick={() => document.getElementById("file-input").click()}
      >
        <input
          id="file-input"
          type="file"
          multiple
          onChange={handleFileChange}
        />
        <img src={cloud} alt="cloud" />
        <p className={[classes.title].join(" ")}>
          Drag Your file(s) or{" "}
          <span className={["c-p main-color "].join(" ")}>browse</span>
        </p>
        <p className={classes.subTitle}>
          Max 5 MB files are allowed up to 5 files max
        </p>
      </div>
      <div className="file-list">
        {files.map((file, index) => (
          <div className="file-item" key={index}>
            <div className="file-icon d-flex justify-content-between w-100">
              <img src={csv} alt="csv img" />

              <span onClick={() => removeFileHandler(file?.name)}>
                <img src={crossIcon} alt="cross icon" />
              </span>
            </div>
            {/* Show progress bar for each file */}

            <div className="d-flex g-8 mt-10">
              <div className={["progress-bar h-100"].join(" ")}>
                <div
                  className="progress"
                  style={{ width: `${uploadProgress[file.name] || 0}%` }}
                ></div>
              </div>

              <p>{Math.round(uploadProgress[file.name])}%</p>
            </div>
            {/* <p>{file.name}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MultipleFileUpload;
