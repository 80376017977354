//get or related cities based onm state Id
import { BaseURL } from "../../config";
import { Get } from "../../AxiosCRUDFunctions";
import { getToken } from "../../helpers/tokenStorage";
import { toast } from "react-toastify";
export const getAllRelatedCitiesApi = async (stateId = 1) => {
  const response = await Get(
    BaseURL(`address/get-cities-by-stateId?StateId=${stateId}`),
    getToken()
  );
  if (response?.data?.headers?.error === 200) return response?.data?.body;
};
export const getAllProvinceApi = async (countryId = 1) => {
  const response = await Get(
    BaseURL(`address/get-states-by-countryId?CountryId=${countryId}`),
    getToken()
  );

  if (response?.data?.headers?.error === 200) {
    return response?.data?.body;
  } else {
    toast.error(response?.data?.headers?.error === 200);
    return [];
  }
};
