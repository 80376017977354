import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchLocationList,
  addLocationToAPI,
  updateLocationInAPI,
  getAllLocations,
  getLocationByIdFromAPI,
} from "./locationApi";

export const fetchLocations = createAsyncThunk(
  "location/get-location-list",
  fetchLocationList
);
export const addLocation = createAsyncThunk(
  "location/add-location",
  addLocationToAPI
);
export const updateLocation = createAsyncThunk(
  "location/update-location",
  updateLocationInAPI
);
export const getLocationById = createAsyncThunk(
  "location/get-location-ById",
  getLocationByIdFromAPI
);

export const getLocations = createAsyncThunk(
  "location/get-locations",
  getAllLocations
);
