import { createSlice } from "@reduxjs/toolkit";
import { createAsyncCases } from "../../../helpers/reduxUtils";
import {
  fetchLocationTypes,
  getAllActiveLocationTypes,
  addLocationType,
  updateLocationType,
} from "../../../api/locationType/locationTypeThunk";
const initialState = {
  activeItems: [],
  items: [],
  status: "idle",
  loading: null,
  error: null,
  totalRowsCount: 0,
  selectedItem: null,
};
const locationTypeSlice = createSlice({
  name: "locationType",
  initialState,
  reducers: {
    handleSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },

  extraReducers(builder) {
    //get all active locations
    createAsyncCases(builder, getAllActiveLocationTypes, {
      fulfilled(state, action) {
        state.activeItems = action?.payload;
      },
    });
    //fetch all location  add or edit
    createAsyncCases(builder, fetchLocationTypes, {
      fulfilled(state, action) {
        state.items = action?.payload?.data;
      },
    });
    createAsyncCases(builder, addLocationType, {
      fulfilled(state, action) {},
    });
    createAsyncCases(builder, updateLocationType);
  },
});

export default locationTypeSlice.reducer;
export const { handleSelectedItem } = locationTypeSlice.actions;
